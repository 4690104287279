import React from 'react'
import Page from 'components/Elements/Page'
import {Col, Row} from 'react-bootstrap'

function EmailNotVerified() {

    return (
        <Page>
            <Row>
                <Col>
                    <h1>Email Not Verified</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Check your inbox/spam for the verification email.</p>
                    <p>After clicking the link in the email you should be able to access the app.</p>
                </Col>
            </Row>
        </Page>
    )
}

export default EmailNotVerified