import React from 'react'
import { withRouter } from "react-router";

import * as ROUTES from 'constants/routes'
import { Card, Button } from 'react-bootstrap';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error: error.stack, errorInfo })
    }

    componentWillUnmount() {
        console.log(this.state)
        this.recoverFromCrash()
    }

    recoverFromCrash = () => {
        this.setState({ error: null, errorInfo: null })
        this.props.history.push(ROUTES.HOME)
    }

    render() {
        const { errorInfo } = this.state
        if (errorInfo) {
            // You can render any custom fallback UI
            return (
                <Card>
                    <Card.Header>Whoops. Something went Wrong</Card.Header>
                    <Card.Body>
                        You have stumbled across a rare specimen of a bug. This is of great help to the developers. To help us make this app better, please email the developers .
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={this.recoverFromCrash} size="small">
                            Get me home
                        </Button>
                    </Card.Footer>
                </Card>
            )
        }

        if (!errorInfo) {
            return this.props.children;
        }

        return null
    }
}

export default withRouter(ErrorBoundary)