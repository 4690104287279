import React from 'react'
import styled from 'styled-components'
import { categories, urgencies } from 'constants/sos'
import { ListGroup, Badge } from 'react-bootstrap'
import { useHistory } from 'react-router'

import * as ROUTES from 'constants/routes'
import DotMenu from 'components/Elements/DotMenu'
import Pill from 'components/Elements/Pill'

const ItemWrapper = styled.div`
.color {
    color: ${({ theme, color }) => theme[color].main};
}
`

export default function CaseList({ cases, secondaryActions = [] }) {

    const history = useHistory()
    const handleClick = (id) => {
        history.push(`${ROUTES.REQUESTS}/${id}`)
    }

    return (
        <ListGroup>
            {cases.map((caseItem, i) => {
                const { id, category_id, urgency_id, status } = caseItem
                const category = categories[category_id]
                const urgency = urgencies[urgency_id]

                let statusBadge = null
                if (status.short_name === 'complete') {
                    statusBadge = <Badge variant="success" pill>completed</Badge>
                } else if (status.short_name === 'cancelled') {
                    statusBadge = <Badge variant="secondary" pill>cancelled</Badge>
                } else if (status.short_name === 'referred') {
                    statusBadge = <Badge variant="info">referred</Badge>
                } else if (status.short_name === 'in_progres') {
                    statusBadge = <Badge>in progress</Badge>
                } else if (status.short_name === 'waiting') {
                    statusBadge = <Badge variant="warning">waiting</Badge>
                }

                return (
                    <ItemWrapper
                        color={urgency.color}
                        key={id}
                    >
                        <ListGroup.Item
                            className="color d-flex justify-content-between align-items-center"
                        >
                            <div
                                className="w-100"
                                onClick={() => handleClick(id)}
                            >
                                <h4><category.Icon /> {category.name}</h4>
                                <Pill color={urgency.color}>Help Needed {urgency.name}</Pill> {statusBadge}
                            </div>
                            <div>
                                {secondaryActions.length > 0 && (
                                    <DotMenu
                                        id={caseItem.id}
                                        item={caseItem}
                                        actions={secondaryActions}
                                    />
                                )}
                            </div>
                        </ListGroup.Item>
                    </ItemWrapper>
                )
            })}
        </ListGroup>
    )
}
