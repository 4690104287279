import { createSlice } from '@reduxjs/toolkit'
import { FriendRequestAPI } from 'lib/API'

const initialState = {
    loading: false,
    requests: [],
    errors: null,
    total: -1,
}

const friendshipRequestsSlice = createSlice({
    name: 'friendshipRequests',
    initialState,
    reducers: {
        sendRequestStart: (state) => {
            state.loading = true
        },
        sendRequestSuccess: (state) => {
            state.loading = false
        },
        sendRequestFailure: (state, payload) => {
            state.loading = false
            state.errors = payload
        },
        cancelRequestStart: (state) => {
            state.loading = true
        },
        cancelRequestSuccess: (state) => {
            state.loading = false
        },
        cancelRequestFailure: (state, payload) => {
            state.loading = false
            state.errors = payload
        },
        rejectRequestStart: (state) => {
            state.loading = true
        },
        rejectRequestSuccess: (state) => {
            state.loading = false
        },
        rejectRequestFailure: (state, payload) => {
            state.loading = false
            state.errors = payload
        },
        acceptRequestStart: (state) => {
            state.loading = true
        },
        acceptRequestSuccess: (state) => {
            state.loading = false
        },
        acceptRequestFailure: (state, payload) => {
            state.loading = false
            state.errors = payload
        },
    }
})

export const {
    getRequestsStart,
    sendRequestStart,
    sendRequestFailure,
    sendRequestSuccess,
    cancelRequestStart,
    cancelRequestFailure,
    cancelRequestSuccess,
    rejectRequestStart,
    rejectRequestFailure,
    rejectRequestSuccess,
    acceptRequestStart,
    acceptRequestFailure,
    acceptRequestSuccess,
} = friendshipRequestsSlice.actions

export const requestsSelector = state => state.friendshipRequests

export const makeFriendshipRequest = (uid) => {
    return async (dispatch, getState) => {
        dispatch(sendRequestStart())

        const { session: { token, authUser } } = getState()

        try {
            await FriendRequestAPI.request(authUser.id, uid, token)
            dispatch(sendRequestSuccess())
        } catch (error) {
            dispatch(sendRequestFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const cancelFriendshipRequest = (uid) => {
    return async (dispatch, getState) => {
        dispatch(cancelRequestStart)

        const { session: { token, authUser } } = getState()

        try {
            await FriendRequestAPI.cancel(authUser.id, uid, token)
            dispatch(cancelRequestSuccess())
        } catch (error) {
            dispatch(cancelRequestFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const rejectFriendshipRequest = (uid) => {
    return async (dispatch, getState) => {
        dispatch(rejectRequestStart)

        const { session: { token, authUser } } = getState()

        try {
            await FriendRequestAPI.reject(authUser.id, uid, token)
            dispatch(rejectRequestSuccess())
        } catch (error) {
            dispatch(rejectRequestFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const acceptFriendshipRequest = (uid) => {
    return async (dispatch, getState) => {
        dispatch(acceptRequestStart)

        const { session: { token, authUser } } = getState()

        try {
            await FriendRequestAPI.accept(authUser.id, uid, token)
            dispatch(acceptRequestSuccess())
        } catch (error) {
            dispatch(acceptRequestFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export default friendshipRequestsSlice.reducer