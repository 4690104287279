import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

class GeolocationBase {

    static async getCurrentPosition() {
        const coordinates = await Geolocation.getCurrentPosition();
        return coordinates
    }

    static watchPosition(callback) {
        const listener = Geolocation.watchPosition({
            enableHighAccuracy: true,
            timeout: Infinity,
        }, (position, err) => {
            if (err) {
                console.error(err)
            }
            callback(position)
        })
        return listener
    }

    static async clearWatch(id) {
        await Geolocation.clearWatch(id)
        return
    }

    static distance(lat1, lng1, lat2, lng2) {

        const deg2rad = (deg) => deg * Math.PI / 180

        const earthRadius = 6371000

        const phi1 = deg2rad(lat1)
        const phi2 = deg2rad(lat2)

        const deltaPhi = deg2rad(lat2 - lat1)
        const deltaLambda = deg2rad(lng2 - lng1)

        const a = Math.sin(deltaPhi / 2.0) ** 2 + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2.0) ** 2

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

        return Math.round(earthRadius * c)
    }

}

export default GeolocationBase