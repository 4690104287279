import OneSignal from "onesignal-cordova-plugin"
import { store } from "store"
import { updateAccount } from "slices/session"
import { Capacitor, Plugins } from "@capacitor/core"
import * as ROUTES from "constants/routes"

const { App } = Plugins

export default class PushNotificationsBase {
    constructor({ navigateToCase, navigateToChat }) {
        this.navigateToCase = navigateToCase
        this.navigateToChat = navigateToChat
    }

    register() {
        if (process.env.NODE_ENV === "development") {
            OneSignal.setLogLevel(6, 0)
        }

        OneSignal.setAppId(process.env.REACT_APP_ONE_SIGNAL_KEY)

        OneSignal.getDeviceState(function (state) {
            const deviceId = state.userId
            console.log("sending device id", deviceId)
            store.dispatch(
                updateAccount({
                    one_signal_device_id: deviceId,
                })
            )
        })

        if (!Capacitor.isPluginAvailable("PushNotifications")) {
            console.log("push notifications not available on web")
            return
        }

        OneSignal.setNotificationOpenedHandler(({ notification }) => {
            const { type } = notification.additionalData
            if (type === "case" || type === "caseMessage") {
                const { caseId } = notification.additionalData
                this.navigateToCase(caseId)
            } else if (type === "message") {
                const { userId } = notification.additionalData
                this.navigateToChat(userId)
            }
        })

        OneSignal.setNotificationWillShowInForegroundHandler(
            async (notificationReceivedEvent) => {
                let notification = notificationReceivedEvent.getNotification()
                const data = notification.additionalData

                const { isActive } = await App.getState()

                if (!isActive) {
                    return notificationReceivedEvent.complete(notification)
                }

                // If we are currently on the chat/case page then don't complete
                if (data.type === "caseMessage") {
                    if (
                        window.location.href.includes(
                            `${ROUTES.REQUESTS}/${data.caseId}`
                        )
                    ) {
                        notificationReceivedEvent.complete()
                    }
                } else if (data.type === "message") {
                    if (
                        window.location.href.includes(
                            `${ROUTES.CHAT_STUB}${data.userId}`
                        )
                    ) {
                        notificationReceivedEvent.complete()
                    }
                }
                notificationReceivedEvent.complete(notification)
            }
        )
    }
}
