import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { useSnackbar } from "notistack"
import { isEmailVerifiedSelector, sessionSelector } from "slices/session"

import * as ROUTES from "constants/routes"
import PushNotifications from "lib/PushNotifications"
import { getCase } from "slices/cases"

export default () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const snacks = useSnackbar()

    const { token } = useSelector(sessionSelector)
    const isEmailVerified = useSelector(isEmailVerifiedSelector)

    useEffect(() => {
        let pushNotifications

        if (token && isEmailVerified) {
            const navigateToCase = (caseId) => {
                dispatch(getCase(caseId)) // get the case before arriving at the screen
                    .then(() => history.push(`${ROUTES.REQUESTS}/${caseId}`))
                    .catch((error) => {
                        snacks.enqueueSnackbar(error.message, {
                            variant: "info",
                        })
                        history.push(`${ROUTES.RESPONSES}`)
                    })
            }

            const navigateToChat = (userId) => {
                history.push(ROUTES.CHAT_STUB + userId)
            }

            pushNotifications = new PushNotifications({
                navigateToCase,
                navigateToChat,
            })

            document.addEventListener("deviceready", () =>
                pushNotifications.register()
            )
        }
    }, [dispatch, token, isEmailVerified, history, snacks])
}
