import React, { forwardRef } from 'react'
import {
    Dropdown,
    Badge,
    Button
} from 'react-bootstrap'
import Icon from 'components/Elements/Icon'
import { MdNotifications, MdClear } from 'react-icons/md'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { notificationsSelector, dismissNotification, dismissAllNotifications } from 'slices/notifications'
import { useHistory } from 'react-router'
import * as ROUTES from 'constants/routes'
import { getCase } from 'slices/cases'
import { useSnackbar } from 'notistack'

export default function Notifications() {

    const dispatch = useDispatch()
    const history = useHistory()
    const { sosNotifications } = useSelector(notificationsSelector)
    const snacks = useSnackbar()

    const handleViewCase = (caseId) => {
        dispatch(getCase(caseId))
            .then(() => {
                history.push(`${ROUTES.REQUESTS}/${caseId}`)
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'info' })
            })
    }

    const handleDismiss = (id) => {
        dispatch(dismissNotification(id))
    }

    let notificationItems = sosNotifications.map(({ title, body, notification }, i) => (
        createNotificationItem(title, body, notification, i)
    ))

    function createNotificationItem(title, body, notification, i) {
        return (
            <Dropdown.Item key={i} style={{ whiteSpace: 'normal' }}>
                <div className="d-flex justify-content-between">
                    <div onClick={() => handleViewCase(notification.cases_id)}>
                        <strong>{title}</strong>
                        {body.split('\n').map((line, j) => (
                            <p key={`${i}p${j}`} className="mb-0">{line}</p>
                        ))}
                    </div>
                    <Icon size="1.5" onClick={() => handleDismiss(notification.id)}>
                        <MdClear />
                    </Icon>
                </div>
            </Dropdown.Item>)
    }

    const hasNotifications = sosNotifications.length > 0

    return (
        <Dropdown alignRight>
            <Dropdown.Toggle as={NotificationButton} disabled={!hasNotifications}>
                <IconButton>
                    <MdNotifications style={{ color: hasNotifications ? 'white' : 'grey' }} />
                    {hasNotifications && <Badge variant="danger">{sosNotifications.length}</Badge>}
                </IconButton>
            </Dropdown.Toggle>
            {hasNotifications && (
                <Dropdown.Menu as={CustomDropDown} >
                    {notificationItems}
                </Dropdown.Menu>
            )}
        </Dropdown>
    )
}

const CustomDropDown = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        const dispatch = useDispatch()
        const { sosNotifications } = useSelector(notificationsSelector)

        const notificationIds = sosNotifications.map(({ notification }) => notification.id)

        const clearAll = () => {
            dispatch(dismissAllNotifications(notificationIds))
        }

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <NotificationList>
                    {children}
                </NotificationList>
                <Button variant="secondary" block onClick={clearAll}>
                    Clear all
                </Button>
            </div>
        )
    }
)

const NotificationList = styled.div`
    overflow-y: scroll;
    height: 400px;
    width: 300px;
`

const IconButton = styled.div`
position: relative;

display: flex;
justify-content: center;
align-items: center;

width: 50px;
height: 50px;

font-size: 2rem;

.badge {
    position: absolute;
    right: 5px;
    top: 5px;

    font-size: 0.4em;
}
`

const NotificationButton = forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={onClick}
    >
        {children}
    </div>
))