import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { chatsSelector } from 'slices/chats'
import { authUserSelector } from 'slices/session'
import Spinner from 'components/Loading/Spinner'
import { MdDelete } from 'react-icons/md'
import styled from 'styled-components'
import Message from 'views/Chat/Parts/Message'
import MessageInput from './MessageInput'

import { Media } from '@ionic-native/media'
import { File } from '@ionic-native/file'

const MessageStream = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 0 1rem;
    flex-direction: column;
    min-height: 100%;
`

export default function ChatArea({ id, handleSendMessage, handleDeleteMessage, handleVoiceMessage, chatDisabled, isDirect = false }) {

    const [messageText, setMessageText] = useState("")

    const authUser = useSelector(authUserSelector)
    const {
        messages,
        loading,
        errorLoadingChat,
        loadingMoreMessages,
    } = useSelector(chatsSelector)

    const mediaRecorderRef = useRef(false)

    const fileRef = useRef(null)

    const onChange = (value) => {
        setMessageText(value)
    }

    const sendChatMessage = (e) => {
        e.preventDefault()
        handleSendMessage(messageText)
            .then(() => {
                setMessageText("")
            })
    }

    let file

    const startRecordingAudio = (e) => {
        // TODO If this needs to work in the browser, then dive into the previous
        // commits of this file to find the code that makes this work on the browser
        // and use that instead of this.

        const fileDir = File.cacheDirectory || File.tempDirectory
        const fileName = 'recording.mp3'
        const filePath = fileDir + fileName

		if (mediaRecorderRef.current !== false) {
			return;
		}

        file = Media.create(filePath)

        fileRef.current = file

        file.onSuccess.subscribe(async (ret) => {
            let b64 = await File.readAsDataURL(fileDir, fileName)
            b64 = b64.replace(/^data:.+;base64,/, '');
            handleVoiceMessage(b64);
        })

        file.onError.subscribe((err) => console.error("onError: ", err))

        mediaRecorderRef.current = true

        file.startRecord()
    }

    const stopRecordingAudio = (e) => {
		if (mediaRecorderRef.current === false) {
			return;
		}

		fileRef.current.stopRecord();
        console.log(fileRef.current)
		mediaRecorderRef.current = false;
    }


    const messageActions = [
        { actionHandler: handleDeleteMessage, content: (<><MdDelete /> Delete</>) },
    ]


    return (
        <div>
            {loadingMoreMessages && <Spinner />}
            {messages && messages[id] && messages[id].length > 0 && (
                <MessageStream>
                    {messages[id].map((message, i) => (
                        <Message
                            key={`message-${i}`}
                            authUser={authUser}
                            message={message}
                            actions={messageActions}
                            isDirect={isDirect}
                        />
                    ))}
                </MessageStream>
            )}
            {!chatDisabled && (
                <MessageInput
                    sendChatMessage={sendChatMessage}
                    messageText={messageText}
                    onChange={onChange}
                    loading={loading}
                    errorLoadingChat={errorLoadingChat}
                    startRecordingAudio={startRecordingAudio}
                    stopRecordingAudio={stopRecordingAudio}

                />
            )}
        </div>
    )
}
