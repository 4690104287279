import styled from 'styled-components'

export default styled.div`
border: 1px solid black;
flex: 1;

display: flex;
align-items: center;
justify-content: center;

font-size: 2rem;
`