import API from './API'

export default class UsersAPI extends API {

    static async findUsers(searchTerm, next_url, token) {

        const url = next_url ? new URL(next_url) : new URL(`${this.API_URL}/users`)
        url.searchParams.append('s', searchTerm)

        const result = await fetch(url, {
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

}