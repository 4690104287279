import React from 'react'
import {
    Button,
    Card,
    Col,
    Row,
} from 'react-bootstrap'

import withAuthentication from 'components/Session/withAuthentication'
import Page from 'components/Elements/Page'
import { useDispatch } from 'react-redux'
import { disableAccount } from 'slices/session'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'
import { compose } from 'recompose'

function Settings() {

    const dispatch = useDispatch()

    const handleDisableAccount = () => {
        dispatch(disableAccount())
    }

    return (
        <Page>
            <Row>
                <Col>
                    <h1>Settings</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Disable Account
                        </Card.Title>
                            <Card.Text>
                                If you disable your account only the admins can re-enable it.
                        </Card.Text>
                            <Button
                                variant="danger"
                                onClick={handleDisableAccount}
                            >Disable Account</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>

                </Col>
            </Row>
        </Page>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(Settings)