import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFriends } from 'slices/friendships'
import { getCases, getCasesRespondedTo } from 'slices/cases'
import { getNotifications } from 'slices/notifications'
import { sessionSelector } from 'slices/session'

export default () => {

    const dispatch = useDispatch()
    const { token, authUser } = useSelector(sessionSelector)

    useEffect(() => {
        if (token && authUser.email_verified_at !== null) {
            console.log('initial get of app information');
            dispatch(getFriends())
            dispatch(getCases())
            dispatch(getCasesRespondedTo())
            dispatch(getNotifications())
        }
    }, [dispatch, token, authUser.email_verified_at])
}