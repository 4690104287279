export default {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryHover: '#343078',
    mobile: '576px',
    red: {
        main: '#b71c1c',
        veryLight: '#ffcdd2',
    },
    orange: {
        main: '#cf4c0a',
        veryLight: '#ffc387',
    },
    yellow: {
        main: '#e38100',
        veryLight: '#fff9c4',
    },
    midGreen: {
        main: '#558b2f',
        veryLight: '#dcedc8',
    },
    green: {
        main: '#1b5e20',
        veryLight: '#a5d6a7',
    },
}