import React, { useState, useEffect } from 'react'
import { Row, Accordion, Card, Spinner } from 'react-bootstrap'
import Fab from 'components/Elements/Fab'
import { MdAdd } from 'react-icons/md'
import AddFriendModal from './Parts/AddFriendModal'
import withAuthentication from 'components/Session/withAuthentication'
import { getFriends, friendshipsSelector, removeFriendship } from 'slices/friendships'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import UserList from './Parts/UserList'
import {
    cancelFriendshipRequest,
    rejectFriendshipRequest,
    acceptFriendshipRequest,
} from 'slices/friendshipRequests'
import RequestList from './Parts/RequestList'
import FriendsList from './Parts/FriendsList'
import { useHistory } from 'react-router'

import * as ROUTES from 'constants/routes'
import Page from 'components/Elements/Page'
import { compose } from 'recompose'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'

function Friends() {

    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()
    const snacks = useSnackbar()
    const history = useHistory()

    const { pending, friendRequests, friends, loading } = useSelector(friendshipsSelector)

    useEffect(() => {
        dispatch(getFriends())
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: "error" })
            })
    }, [dispatch, snacks])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const cancelRequest = (uid, _username) => {
        dispatch(cancelFriendshipRequest(uid))
            .then(() => {
                dispatch(getFriends())
                snacks.enqueueSnackbar('request cancelled', { variant: 'success' })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'error' })
            })
    }

    const pendingFriendshipsActions = [
        { actionHandler: cancelRequest, content: "Cancel", variant: 'secondary' }
    ]

    const acceptRequest = (uid, username) => {

        dispatch(acceptFriendshipRequest(uid))
            .then(() => {
                dispatch(getFriends())
                snacks.enqueueSnackbar(`${username} added to friend list`, { variant: 'success' })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'error' })
            })
    }

    const rejectRequest = (uid, username) => {
        dispatch(rejectFriendshipRequest(uid))
            .then(() => {
                dispatch(getFriends())
                snacks.enqueueSnackbar('request rejected', { variant: 'success' })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'error' })
            })
    }

    const friendRequestActions = [
        { actionHandler: acceptRequest, content: "Accept" },
        { actionHandler: rejectRequest, content: "Reject", variant: 'secondary' },
    ]

    const messageFriend = (id) => {
        history.push(ROUTES.CHAT_STUB + id)
    }

    const removeFriend = ({ id, username }) => {
        dispatch(removeFriendship(id))
            .then(() => {
                dispatch(getFriends())
                snacks.enqueueSnackbar('friend removed', { variant: 'success' })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'error' })
            })
    }

    const friendActions = [
        { actionHandler: removeFriend, content: 'Remove friend', variant: 'danger' }
    ]

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Friends</h1>
            </Row>
            <Row>
                {friendRequests.length > 0 && (
                    <Card
                        className="flex-fill"
                    >
                        <Card.Body className="p-0">
                            <RequestList
                                users={friendRequests}
                                loading={loading}
                                actions={friendRequestActions}
                            />
                        </Card.Body>
                    </Card>
                )}
            </Row>
            <Row>
                <Accordion
                    className="flex-fill"
                    defaultActiveKey="friends"
                >
                    {pending.length > 0 && (
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="pending">
                                Pending Friendship Requests
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="pending">
                                <Card.Body className="p-0">
                                    <UserList
                                        users={pending}
                                        loading={loading}
                                        secondaryActions={pendingFriendshipsActions}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
            </Row>
            <Row>
                <Card
                    className="flex-fill"
                >
                    <Card.Header>
                        Friends
                    </Card.Header>
                    <Card.Body className="p-0">
                        {loading && <Spinner />}
                        {!loading && friends.length > 0 ? (
                            <FriendsList
                                friends={friends}
                                loading={loading}
                                primaryAction={messageFriend}
                                secondaryActions={friendActions}
                            />

                        ) : (
                                <div className="px-2 py-4 text-center">
                                    Click on the + to start adding friends
                                </div>
                            )}
                    </Card.Body>
                </Card>
            </Row>
            <Fab onClick={() => handleOpen()}>
                <MdAdd />
            </Fab>
            <AddFriendModal
                open={open}
                onClose={handleClose}
            />

        </Page>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(Friends)