import { createSlice } from '@reduxjs/toolkit'
import { CaseAPI } from 'lib/API'
import * as SOS from 'constants/sos'

const initialState = {
    loading: false,
    loadingCase: false,
    respondingToCase: false,
    errors: null,
    cases: {},
    newCase: {
        category_id: null,
        recipients_id: null,
        urgency_id: null,
        latitude: null,
        longitude: null,
    },
    casePagination: {
        next_page_url: null,
        lastPage: null,
    },
    casesRespondedToPagination: {
        next_page_url: null,
        lastPage: null,
    },
}

const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        createCaseStart: (state) => {
            state.loadingCase = true
        },
        createCaseSuccess: (state, { payload: { data } }) => {
            const { caseItem } = data
            state.cases = {
                ...state.cases,
                [caseItem.id]: caseItem,
            }
            state.loadingCase = false
            state.errors = null
        },
        createCaseFailure: (state, { payload }) => {
            state.loadingCase = false
            state.errors = payload
        },
        getCaseStart: (state) => {
            state.loadingCase = true
        },
        getCaseSuccess: (state, { payload: { data } }) => {
            state.cases[data.id] = data
            state.loadingCase = false
            state.errors = null
        },
        getCaseFailure: (state, { payload }) => {
            state.loadingCase = false
            state.errors = payload
        },
        getCasesStart: (state) => {
            state.loadingCase = true
        },
        getCasesSuccess: (state, { payload: { data, pagination } }) => {
            data.forEach((caseItem) => {
                state.cases[caseItem.id] = caseItem
            })
            state.casePagination.lastPage = pagination.lastPage
            state.casePagination.next_page_url = pagination.next_page_url
            state.loadingCase = false
            state.errors = null
        },
        getCasesFailure: (state, { payload }) => {
            state.loadingCase = false
            state.errors = payload
        },
        getCasesRespondedToStart: (state) => {
            state.loading = true
        },
        getCasesRespondedToSuccess: (state, { payload: { data, pagination } }) => {
            data.forEach((caseItem) => {
                state.cases[caseItem.id] = caseItem
            })
            state.casesRespondedToPagination.lastPage = pagination.lastPage
            state.casesRespondedToPagination.next_page_url = pagination.next_page_url
            state.loading = false
            state.errors = null
        },
        getCasesRespondedToFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        addCategoryToNewCase: (state, { payload }) => {
            state.newCase = {
                ...state.newCase,
                category_id: payload,
            }
        },
        addUrgencyToNewCase: (state, { payload }) => {
            state.newCase = {
                ...state.newCase,
                urgency_id: payload,
            }
        },
        addRecipientsToNewCase: (state, { payload }) => {
            state.newCase = {
                ...state.newCase,
                recipients_id: payload,
            }
        },
        addLocationToNewCase: (state, { payload: { latitude, longitude } }) => {
            state.newCase.latitude = latitude
            state.newCase.longitude = longitude
        },
        clearNewCase: (state) => {
            state.newCase = {
                category_id: null,
                urgency_id: null,
                recipients_id: null,
                latitude: null,
                longitude: null,
            }
        },
        updateCaseStart: (state) => {
            state.loading = true
        },
        updateCaseSuccess: (state, { payload: { data } }) => {
            state.cases = {
                ...state.cases,
                [data.id]: data,
            }
            state.loading = false
        },
        updateCaseFailure: (state, payload) => {
            state.errors = payload
            state.loading = false
        },
        caseUpdated: (state, { payload: { caseItem } }) => {
            state.cases[caseItem.id] = caseItem
        },
        updateCaseLocationStart: (state) => {
            state.loading = true
            state.errors = null
        },
        updateCaseLocationSuccess: (state, { payload: { data } }) => {
            state.cases = {
                ...state.cases,
                [data.id]: data,
            }
            state.loading = false
        },
        updateCaseLocationFailure: (state, { payload }) => {
            state.errors = payload
            state.loading = false
        },
        locationUpdated: (state, { payload }) => {
            const { caseId, location } = payload
            if (state.cases[caseId]) {
                state.cases[caseId].latitude = location.latitude
                state.cases[caseId].longitude = location.longitude
            }
        },
        respondToCaseStart: (state) => {
            state.respondingToCase = true
        },
        respondToCaseSuccess: (state) => {
            state.respondingToCase = false
        },
        respondToCaseFailure: (state, { payload }) => {
            state.respondingToCase = false
            state.errors = payload
        },
        passCaseStart: (state) => {
            state.respondingToCase = true
        },
        passCaseSuccess: (state) => {
            state.respondingToCase = false
        },
        passCaseFailure: (state, { payload }) => {
            state.respondingToCase = false
            state.errors = payload
        },
        completeCaseStart: (state) => {
            state.respondingToCase = true
        },
        completeCaseSuccess: (state) => {
            state.respondingToCase = false
        },
        completeCaseFailure: (state, { payload }) => {
            state.respondingToCase = false
            state.errors = payload
        },
        cancelCaseStart: (state) => {
            state.loading = true
        },
        cancelCaseSuccess: (state, { payload: { data } }) => {
            state.cases = {
                ...state.cases,
                [data.id]: data,
            }
            state.loading = false
        },
        cancelCaseFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        caseCancelled: (state, { payload: { caseItem } }) => {
            state.cases[caseItem.id] = caseItem
        },
    }
})

export const {
    getCaseStart,
    getCaseSuccess,
    getCaseFailure,
    getCasesStart,
    getCasesSuccess,
    getCasesFailure,
    getCasesRespondedToStart,
    getCasesRespondedToSuccess,
    getCasesRespondedToFailure,
    createCaseStart,
    createCaseSuccess,
    createCaseFailure,
    addCategoryToNewCase,
    addRecipientsToNewCase,
    addUrgencyToNewCase,
    addLocationToNewCase,
    clearNewCase,
    updateCaseStart,
    updateCaseSuccess,
    updateCaseFailure,
    updateCaseLocationStart,
    updateCaseLocationSuccess,
    updateCaseLocationFailure,
    respondToCaseStart,
    respondToCaseSuccess,
    respondToCaseFailure,
    passCaseStart,
    passCaseSuccess,
    passCaseFailure,
    completeCaseStart,
    completeCaseSuccess,
    completeCaseFailure,
    cancelCaseStart,
    cancelCaseFailure,
    cancelCaseSuccess,
    locationUpdated,
    caseUpdated,
    caseCancelled,
} = casesSlice.actions

export const casesSelector = state => state.cases
export const mySOSesSelector = ({
    cases: {
        cases,
        loading
    },
    session: {
        authUser
    }
}) => {
    const myCases = Object.fromEntries(Object.entries(cases)
        .filter(([caseId, caseItem]) =>
            caseItem.user_id === authUser.id))
    return {
        cases: myCases,
        loading
    }
}
export const myCasesRespondedToSelector = ({
    cases: {
        cases,
        loading
    },
    session: {
        authUser
    }
}) => {
    const myCases = Object.fromEntries(Object.entries(cases)
        .filter(([caseId, caseItem]) => caseItem.responder_id === authUser.id))
    return {
        cases: myCases,
        loading
    }
}

export const caseSelector = (caseId) => ({ cases: { cases } }) => {
    const caseItem = cases[caseId]
    if (!caseItem) return {}
    return {
        ...caseItem,
        urgency: SOS.urgencies[caseItem.urgency_id],
        recipients: SOS.recipients[caseItem.recipients_id],
        category: SOS.categories[caseItem.category_id],
    }
}

export const createCase = () => async (dispatch, getState) => {
    dispatch(createCaseStart())

    const { session: { token }, cases: { newCase }, session: { authUser } } = getState()

    let data = newCase
    if (!newCase.longitude || !newCase.latitude) {
        data = {
            ...newCase,
            latitude: authUser.latitude,
            longitude: authUser.longitude,
        }
    }
    try {
        let body = await CaseAPI.create(data, token)
        dispatch(createCaseSuccess(body))
        return body
    } catch (error) {
        dispatch(createCaseFailure(error.message))
        throw new Error(error.message)
    }
}

export const getCase = (caseId) => async (dispatch, getState) => {
    dispatch(getCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.get(caseId, token)
        dispatch(getCaseSuccess(body))
        return
    } catch (error) {
        dispatch(getCaseFailure(error.message))
        throw new Error(error.message)
    }
}

export const getCases = () => async (dispatch, getState) => {
    dispatch(getCasesStart())

    const { session: { token } } = getState()

    try {
        let closedCases = await CaseAPI.getAll({
            isOpen: false,
        }, token)
        let openCases = await CaseAPI.getAll({
            isOpen: true,
        }, token)
        const body = {
            data: [
                ...openCases.data,
                ...closedCases.data.data,
            ],
            pagination: {
                next_page_url: closedCases.data.next_page_url,
                lastPage: closedCases.data.last_page,
            }
        }

        dispatch(getCasesSuccess(body))
        return
    } catch (error) {
        dispatch(getCasesFailure(error.message))
        throw new Error(error.message)
    }
}

export const getCasesRespondedTo = () => async (dispatch, getState) => {
    dispatch(getCasesRespondedToStart())

    const { session: { token } } = getState()

    try {
        let closedCases = await CaseAPI.getAll({
            isOpen: false,
            responder: true,
        }, token)
        let openCases = await CaseAPI.getAll({
            isOpen: true,
            responder: true,
        }, token)
        const body = {
            data: [
                ...openCases.data,
                ...closedCases.data.data,
            ],
            pagination: {
                next_page_url: closedCases.data.next_page_url,
                lastPage: closedCases.data.last_page,
            }
        }

        dispatch(getCasesRespondedToSuccess(body))
        return
    } catch (error) {
        dispatch(getCasesRespondedToFailure(error.message))
        throw new Error(error.message)
    }
}

export const updateCase = (caseId, data) => async (dispatch, getState) => {
    dispatch(updateCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.update(caseId, data, token)
        dispatch(updateCaseSuccess(body))
        return
    } catch (error) {
        dispatch(updateCaseFailure(error.message))
        console.log(error)
    }
}

export const updateCaseLocation = (caseId, data) => async (dispatch, getState) => {
    dispatch(updateCaseLocationStart())

    const { session: { token }, cases: { cases } } = getState()

    if (data.latitude === cases[caseId].latitude && data.longitude === cases[caseId].longitude) {
        dispatch(updateCaseLocationFailure('location hasnt changed'))
        return
    }

    try {
        let body = await CaseAPI.updateLocation(caseId, data, token)
        dispatch(updateCaseLocationSuccess(body))
        return
    } catch (error) {
        dispatch(updateCaseLocationFailure(error.message))
        console.log(error)
    }
}

export const respondToCase = (caseId) => async (dispatch, getState) => {
    dispatch(respondToCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.respond(caseId, token)
        dispatch(respondToCaseSuccess(body))
        return
    } catch (error) {
        dispatch(respondToCaseFailure(error.message))
        console.log(error)
    }
}

export const passCase = (caseId) => async (dispatch, getState) => {
    dispatch(passCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.pass(caseId, token)
        dispatch(passCaseSuccess(body))
        return
    } catch (error) {
        dispatch(passCaseFailure(error.message))
        console.log(error)
    }
}

export const completeCase = (caseId) => async (dispatch, getState) => {
    dispatch(completeCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.complete(caseId, token)
        dispatch(completeCaseSuccess(body))
        return
    } catch (error) {
        dispatch(completeCaseFailure(error.message))
        console.log(error)
    }
}

export const cancelCase = (caseId) => async (dispatch, getState) => {
    dispatch(cancelCaseStart())

    const { session: { token } } = getState()

    try {
        let body = await CaseAPI.cancel(caseId, token)
        dispatch(cancelCaseSuccess(body))
        return
    } catch (error) {
        dispatch(cancelCaseFailure(error.message))
        console.log(error)
    }
}

export default casesSlice.reducer