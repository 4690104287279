import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage"
import devToolsEnhancer from "remote-redux-devtools"

import rootReducer from "slices"
import { persistReducer, persistStore } from "redux-persist"
import { hydrationSuccess } from "slices/session"

const idbStorageConfig = {
    name: process.env.REACT_APP_NAME,
    storeName: "keyval",
}

const persistConfig = {
    key: "root",
    whitelist: ["session"],
    storage: createIdbStorage(idbStorageConfig),
}

let remoteDevOptions = {}

if (process.env.REACT_APP_REMOTE_DEV === "on") {
    const remoteDevConfig = {
        realtime: true,
        hostname: process.env.REACT_APP_SERVER_HOST,
        port: "9000",
    }
    remoteDevOptions = {
        devTools: false,
        enhancers: [devToolsEnhancer(remoteDevConfig)],
    }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    ...remoteDevOptions,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
})

const postRehydrate = () => {
    store.dispatch(hydrationSuccess())
}

const persistor = persistStore(store, null, postRehydrate)

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("slices", () => {
        const newRootReducer = require("slices").default
        store.replaceReducer(persistReducer(persistConfig, newRootReducer))
    })
}

export { store, persistor }
