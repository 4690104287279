import React, { useCallback } from 'react'
import styled from 'styled-components'
import OuterMessageWrap from 'views/Chat/Parts/OuterMessageWrap'
import ChatArea from 'views/Chat/Parts/ChatArea'
import { useDispatch, useSelector } from 'react-redux'
import { loadCaseChat, sendMessage, addSentMessage, deleteMessage, loadMoreMessages, markMessageDeleted, sendAudioMessage } from 'slices/chats'
import { useSnackbar } from 'notistack'
import { authUserSelector } from 'slices/session'

const WrapStyle = styled.div`
    position: absolute;
    top: 6rem;
    bottom: 4.5rem;
    left: 0;
    right: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
`

export default function SosChatArea({ chatId, caseId, withNotifications, chatDisabled }) {
    const dispatch = useDispatch()
    const snacks = useSnackbar()
    const authUser = useSelector(authUserSelector)

    const handleLoadChat = useCallback(() => {
        dispatch(loadCaseChat(caseId))
            .catch((error) => {
                console.error(error.message)
            })
    }, [dispatch, caseId])

    const handleSendMessage = (messageText) => dispatch(sendMessage(messageText, caseId))

    const handleMessageSent = useCallback(({ message }) => {
        dispatch(addSentMessage({
            id: chatId,
            newMessage: message,
            authUserId: authUser.id,
        }))
        if (withNotifications) {
            snacks.enqueueSnackbar(message.content, { variant: authUser.id === message.user_id ? 'default' : 'info' })
        }
    }, [dispatch, chatId, withNotifications, snacks, authUser.id])

    const handleDeleteMessage = (messageId) => {
        dispatch(deleteMessage(messageId, caseId))
    }

    const handleMessageDeleted = useCallback(({ message }) => {
        dispatch(markMessageDeleted({
            id: chatId,
            deletedMessage: message,
        }))
    }, [dispatch, chatId])

    const handleLoadMore = () => {
        dispatch(loadMoreMessages(caseId))
    }

	const handleVoiceMessage = (b64) => {
		dispatch(sendAudioMessage(b64, caseId));
	}

    return (
        <OuterMessageWrap
            handleLoadMore={handleLoadMore}
            handleMessageSent={handleMessageSent}
            handleMessageDeleted={handleMessageDeleted}
            handleLoadChat={handleLoadChat}
            id={chatId}
            WrapStyle={WrapStyle}
        >
            <ChatArea
                id={chatId}
                handleSendMessage={handleSendMessage}
                handleDeleteMessage={handleDeleteMessage}
                handleVoiceMessage={handleVoiceMessage}
                chatDisabled={chatDisabled}
                isDirect={false}
            />
        </OuterMessageWrap>
    )
}
