import API from './API'

export default class ChatAPI extends API {

    static async create(userId, token) {

        const result = await fetch(`${this.API_URL}/chats/${userId}`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async sendMessage(userId, message, token) {

        const result = await fetch(`${this.API_URL}/chats/${userId}/messages`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify({
                'content': message,
            })
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

	static async sendAudioMessage (userId, b64message, token, onPartReady ) {
		const makeUrl = (i, numChunks) => `${this.API_URL}/chats/${userId}/audioMessages/` + (i + 1) + `/` + numChunks

        return await this.sendAudio(makeUrl, b64message, token, onPartReady)
	}

    static async sendCaseMessage(caseId, message, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}/messages`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify({
                'content': message,
            })
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

	static async sendAudioCaseMessage (caseId, b64message, token, onPartReady ) {
		const makeUrl = (i, numChunks) => `${this.API_URL}/cases/${caseId}/audioMessages/` + (i + 1) + `/` + numChunks

		return await this.sendAudio(makeUrl, b64message, token, onPartReady)
	}

    static async deleteMessage(userId, messageId, token) {

        const result = await fetch(`${this.API_URL}/chats/${userId}/messages/${messageId}`, {
            method: 'DELETE',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async deleteCaseMessage(caseId, messageId, token) {

        const result = await fetch(`${this.API_URL}/chats/${caseId}/messages/${messageId}`, {
            method: 'DELETE',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }


    static async getMessages(userId, token) {

        const result = await fetch(`${this.API_URL}/chats/${userId}/messages`, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getNewMessages(userId, dateString, token) {

        const url = new URL(`${this.API_URL}/chats/${userId}/messages`)
        url.searchParams.append('newerThan', dateString)

        const result = await fetch(url, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getMoreMessages(userId, dateString, token) {

        const url = new URL(`${this.API_URL}/chats/${userId}/messages`)
        url.searchParams.append('olderThan', dateString)

        const result = await fetch(url, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getCaseMessages(caseId, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}/messages`, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getNewCaseMessages(caseId, dateString, token) {

        const url = new URL(`${this.API_URL}/cases/${caseId}/messages`)
        url.searchParams.append('newerThan', dateString)

        const result = await fetch(url, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getMoreCaseMessages(caseId, dateString, token) {

        const url = new URL(`${this.API_URL}/cases/${caseId}/messages`)
        url.searchParams.append('olderThan', dateString)

        const result = await fetch(url, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async sendAudio(makeUrl, b64message, token, onPartReady) {
        var size = 12*1024

		const numChunks = Math.ceil(b64message.length / size)
		var body = false;

		var myUniqueId = Math.floor(new Date().getTime() * Math.random()) + ".webm";

		for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
		    var content = b64message.substr(o, size);

			const result = await fetch(makeUrl(i, numChunks), {
	            method: 'POST',
	            headers: this.combineHeaders([
	                this.authHeader(token),
	                this.sendJsonHeader(),
	            ]),
	            body: JSON.stringify({
					'name': myUniqueId,
	                'data': content,
	            })
	        })

	        body = await result.json()

	        this.checkResultOK(result, body)

			onPartReady({
				part: i,
				off: numChunks,
				perc: Math.round(((i+1)/numChunks)*100)
			});
		}

        return body
    }
}