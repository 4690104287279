import React from 'react'
import PropTypes from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import UserItem from './UserItem'
import Spinner from 'components/Loading/Spinner'
import { useSelector } from 'react-redux'
import { authUserSelector } from 'slices/session'

export default function UserList({ users, loading = false, activeList = false, activeUserId = null, primaryAction = null, secondaryActions = [], ...props }) {

    const authUser = useSelector(authUserSelector)

    return (
        <>
            <ListGroup {...props}>
                {
                    users.filter(({ id }) => id !== authUser.id)
                        .map(({ username, avatar, id }) => (
                            <UserItem
                                username={username}
                                avatar={avatar}
                                key={username}
                                active={activeList && activeUserId === id}
                                primaryAction={primaryAction}
                                id={id}
                                activeList={activeList}
                                secondaryActions={secondaryActions}
                            />
                        ))
                }
            </ListGroup>
            {loading && <Spinner />}
        </> 
    )
}

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    activeList: PropTypes.bool,
    activeUserId: PropTypes.number,
    primaryAction: PropTypes.func,
    secondaryActions: PropTypes.array,
}