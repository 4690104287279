import React, { useState } from "react"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, Redirect } from "react-router-dom"

import { Row, Form, Button } from "react-bootstrap"
import MaskedInput from "components/Elements/MaskedInput"

import * as ROUTES from "constants/routes"
import { register, sessionSelector } from "slices/session"
import Page from "components/Elements/Page"

const INITIAL_FORM_STATE = {
    username: "",
    password: "",
    password_confirmation: "",
    full_name: "",
    email: "",
    phone: "",
    cnic: "",
}

function Register() {
    const [registerDetails, setRegisterDetails] = useState(INITIAL_FORM_STATE)

    const history = useHistory()
    const snacks = useSnackbar()
    const dispatch = useDispatch()
    const { loading, authUser, token } = useSelector(sessionSelector)

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(register(registerDetails))
            .then(() => {
                snacks.enqueueSnackbar("Registered successfully", {
                    variant: "success",
                })
                history.push(ROUTES.HOME)
            })
            .catch((error) => {
                snacks.enqueueSnackbar(`Error: ${error.message}`, {
                    variant: "error",
                })
            })
    }

    const onChange = (name, value) => {
        setRegisterDetails({
            ...registerDetails,
            [name]: value,
        })
    }

    const authenticated = authUser && token

    if (authenticated) return <Redirect to={ROUTES.HOME} />

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Register</h1>
            </Row>
            <Row className="justify-content-center">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label srOnly>Email</Form.Label>
                        <Form.Control
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            autoComplete="email"
                            value={registerDetails.email}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label srOnly>Password</Form.Label>
                        <Form.Control
                            placeholder="Password"
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            value={registerDetails.password}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPasswordConfirm">
                        <Form.Label srOnly>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            placeholder="Confirm Password"
                            type="password"
                            name="password_confirmation"
                            autoComplete="confirm-password"
                            value={registerDetails.password_confirmation}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicUsername">
                        <Form.Label srOnly>Username</Form.Label>
                        <Form.Control
                            placeholder="Username"
                            name="username"
                            autoComplete="username"
                            value={registerDetails.username}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicfullname">
                        <Form.Label srOnly>Full Name</Form.Label>
                        <Form.Control
                            required
                            placeholder="Full Name"
                            name="full_name"
                            autoComplete="name"
                            value={registerDetails.full_name}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPhone">
                        <Form.Label srOnly>Phone Number</Form.Label>
                        <Form.Control
                            required
                            placeholder="Phone Number"
                            name="phone"
                            autoComplete="phone"
                            value={registerDetails.phone}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="formCnic">
                        <Form.Label srOnly>CNIC</Form.Label>
                        <Form.Control
                            as={MaskedInput}
                            mask="11111-1111111-1"
                            required
                            placeholder="CNIC"
                            name="cnic"
                            autoComplete="cnic"
                            value={registerDetails.cnic}
                            onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Group>
                    <Button
                        type="submit"
                        variant="primary"
                        block
                        disabled={loading}
                    >
                        Register
                    </Button>
                </Form>
            </Row>
            <Row className="justify-content-center mt-3">
                <p>
                    Already registered? <Link to={ROUTES.LOGIN}>Log in</Link>
                </p>
            </Row>
        </Page>
    )
}

export default Register
