import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
    margin-top: ${({ $hasMenuBar }) => $hasMenuBar ? '3rem' : '0'};
`

export default function Page({ children, hasMenuBar = true, ...other }) {

    return (
        <StyledContainer
            $hasMenuBar={hasMenuBar}
            {...other}
            className="pt-4"
        >
            {children}
        </StyledContainer>
    )
}