import React, { useState, useRef, forwardRef } from 'react'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Dropdown } from 'react-bootstrap'
import {
    Link, useHistory,
} from "react-router-dom"
import { logout, authUserSelector } from 'slices/session';
import { Button } from 'react-bootstrap'
import Menu from 'components/Common/Menu'
import Burger from 'components/Common/Burger';
import useOnClickOutside from 'hooks/useOnClickOutside';
import * as ROUTES from 'constants/routes'
import { Avatar } from 'components/Elements/Avatar';
import SosButton from 'views/SOS/Parts/SosButton';
import Icon from 'components/Elements/Icon';
import { MdPerson, MdVpnKey, MdPowerSettingsNew, MdSettings } from 'react-icons/md';
import Notifications from 'components/Notifications/Notifications';
import styled from 'styled-components';

export default function MenuBar() {

    const [menuOpen, setMenuOpen] = useState(false)
    const snacks = useSnackbar()
    const dispatch = useDispatch()
    const history = useHistory()

    const authUser = useSelector(authUserSelector)

    const isEmailVerified = authUser.email_verified_at !== null

    const node = useRef()
    useOnClickOutside(node, () => setMenuOpen(false))

    const toggleMenu = () => setMenuOpen(open => !open)

    const handleLogout = () => {
        setMenuOpen(false)
        dispatch(logout())
            .catch((error) => {
                snacks.enqueueSnackbar(`Error: ${error.message}`, { variant: "error" })
            })
    }

    const handleGoHome = () => {
        history.push(ROUTES.HOME)
    }

    return (
        <Navbar
            fixed="top"
            variant="dark"
            bg="dark"
            className="d-flex justify-content-between py-2 px-3"
        >
            <div ref={node}>
                {(isEmailVerified) && (
                    <Burger
                        open={menuOpen}
                        toggleOpen={toggleMenu}
                    />
                )}

                <Menu
                    open={menuOpen}
                >
                    <Link to={ROUTES.HOME} onClick={() => { setMenuOpen(false) }}>
                        SOS
                    </Link>
                    <Link to={ROUTES.REQUESTS} onClick={() => { setMenuOpen(false) }}>
                        My Requests
                    </Link>
                    <Link to={ROUTES.RESPONSES} onClick={() => { setMenuOpen(false) }}>
                        Responses
                    </Link>
                    <Link to={ROUTES.FRIENDS} onClick={() => { setMenuOpen(false) }}>
                        Friends
                    </Link>
                    <Button onClick={handleLogout}>
                        Logout
                    </Button>
                    <Version>
                        <p>Version: {process.env.REACT_APP_VERSION}</p>
                    </Version>
                </Menu>
            </div>
            <div className="d-flex">

                <SosButton
                    size='50'
                    borderSize='5'
                    variant='button'
                    className="mr-2"
                    onClick={handleGoHome}
                />

                <Dropdown alignRight>
                    <Dropdown.Toggle as={AccountButton}>
                        <Avatar
                            size="small"
                            avatar={authUser.avatar}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu flip>
                        <Dropdown.Header>@{authUser.username.replace(' ', '')}</Dropdown.Header>
                        <Dropdown.Item
                            as={Link}
                            to={ROUTES.ACCOUNT}
                        >
                            <Icon><MdPerson /></Icon> Account
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={ROUTES.CHANGE_PASSWORD}
                        >
                            <Icon><MdVpnKey /></Icon> Change Password
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={ROUTES.SETTINGS}
                        >
                            <Icon><MdSettings /></Icon> Settings
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={handleLogout}
                        >
                            <Icon><MdPowerSettingsNew /></Icon> Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Notifications />

            </div>
        </Navbar >
    )
}

const Version = styled.div`
    color: #7e7d99;
    position: absolute;
    bottom: 0;
    font-size: 0.9rem;
`

const AccountButton = forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={onClick}
    >
        {children}
    </div>
))
