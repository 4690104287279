import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { uploadAvatar } from 'slices/session'
import { useSnackbar } from 'notistack'

function EditAvatar(props) {

    const { open, handleClose } = props
    const [avatar, setAvatar] = useState("")

    const dispatch = useDispatch()
    const snacks = useSnackbar()

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(uploadAvatar({
            avatar
        }))
        .then(() => {
            snacks.enqueueSnackbar("Uploaded avatar", { variant: "success"})
            handleClose()
        })
        .catch((error) => {
            snacks.enqueueSnackbar(`Error: ${error.message}`, { variant: "error"})
        })
    }

    const handleCancel = () => {
        setAvatar("")
        handleClose()
    }

    return (
        <Modal show={open} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Avatar</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            type="file"
                            name="avatar"
                            onChange={e => setAvatar(e.target.files[0])}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit">
                        Upload
                </Button>
                    <Button
                        variant="secondary"
                        onClick={handleCancel}
                    >
                        Cancel
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EditAvatar