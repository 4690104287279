import { useEffect } from "react"
import { useHistory } from "react-router"
import { Plugins } from "@capacitor/core"

const { App } = Plugins

export default () => {
    let history = useHistory()

    useEffect(() => {
        console.log('adding url listener ');
        App.addListener('appUrlOpen', ({ url }) => {
            const { pathname, search } = new URL(url)
            const slug = pathname + search
            history.push(slug)
        })
    }, [history])

    return null
}