import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export default styled(Button)`
position: relative;
z-index: 100;

width: 3.5rem;
height: 3.5rem;

font-size: 1.5rem;
padding: 0.5rem;
border-radius: 100px;

`