import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { sessionSelector } from 'slices/session';
import { connectEcho, disconnectEcho } from "slices/socket";

export default () => {

    const dispatch = useDispatch()
    const { token } = useSelector(sessionSelector)

    useEffect(() => {
        if (token) {
            dispatch(connectEcho())
        }

        return () => {
            dispatch(disconnectEcho())
        };
    }, [dispatch, token])
}