import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { Avatar } from 'components/Elements/Avatar'
import { MdMessage } from 'react-icons/md'
import DotMenu from 'components/Elements/DotMenu'

export default function FriendItem({ friend, primaryAction, secondaryActions }) {

    const { id, username, avatar } = friend

    return (
        <ListGroup.Item
            className="d-flex justify-content-between"
        >
            <div className="d-flex align-items-center">
                <Avatar
                    avatar={avatar}
                    size="small"
                />
                <span className="ml-2">{username}</span>
            </div>
            <div className="d-flex align-items-center">
                <Button className="mr-1" onClick={() => primaryAction(id, username)}>
                    <MdMessage />
                </Button>
                {secondaryActions.length > 0 && (
                    <DotMenu 
                        id={friend.id}
                        item={friend}
                        actions={secondaryActions}
                    />
                )}
            </div>
        </ListGroup.Item>
    )
}
