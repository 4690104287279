import React from 'react'
import { ListGroup } from 'react-bootstrap'
import FriendItem from './FriendItem'

export default function FriendsList({friends, primaryAction, secondaryActions = []}) {
    return (
        <ListGroup>
            {friends.map((friend) => (
                <FriendItem
                    friend={friend}
                    primaryAction={primaryAction}
                    secondaryActions={secondaryActions}
                    key={`friends-${friend.id}`}
                />
            ))}
        </ListGroup>
    )
}
