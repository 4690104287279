import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { MdError } from 'react-icons/md'

export default function ErrorMessage({ title, message, action, ...props }) {
    return (
        <Card {...props}>
            <Card.Header className="text-danger"><MdError /> {title}</Card.Header>
            <Card.Body>
                <Card.Text>
                    {message}
                </Card.Text>
                <Button onClick={action.handler}>
                    {action.text}
                </Button>
            </Card.Body>
        </Card>
    )
}
