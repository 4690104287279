import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    align-items: center;
    text-align: left;
    background: ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.primaryDark};
    text-rendering: optimizeLegibility;
    height: 100vh;
    max-width: 100vw;
    /* padding: 1.5rem 0.5rem; */
  }
  `

export default GlobalStyles