import React, { createRef } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import Main from "views/Main/Main"
import { SnackbarProvider } from "notistack"
import { Provider, useSelector } from "react-redux"
import { store, persistor } from "store"
import { sessionSelector } from "slices/session"
import { IconButton } from "@material-ui/core"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider } from "styled-components"
import Spinner from "components/Loading/Spinner"
import theme from "constants/theme"
import GlobalStyles from "constants/globalStyles"
import ErrorBoundary from "components/Common/ErrorBoundary"
import { MdClose } from "react-icons/md"
import MenuBar from "components/Common/MenuBar"
import { uiSelector } from "slices/ui"
import useUpdateLocationInterval from "hooks/useUpdateLocationInterval"
import useSocketConnection from "hooks/useSocketConnection"
import useRegisterPushNotifications from "hooks/useRegisterPushNotifications"
import useInitialiseAppData from "hooks/useInitialiseAppData"
import useListenToNotifications from "hooks/useListenToNotifications"
import useAppUrlListener from "hooks/useAppUrlListener"

function App() {
    const notistackRef = createRef()
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key)
    }

    return (
        <Provider store={store}>
            <PersistGate loading={<Spinner />} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        ref={notistackRef}
                        action={(key) => (
                            <IconButton
                                onClick={onClickDismiss(key)}
                                component="span"
                            >
                                <MdClose />
                            </IconButton>
                        )}
                    >
                        <GlobalStyles />
                        <Router>
                            <Inner />
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}

function Inner() {
    const { authUser, token } = useSelector(sessionSelector)
    const { menuBarOn } = useSelector(uiSelector)

    useSocketConnection()
    useUpdateLocationInterval()
    useRegisterPushNotifications()
    useInitialiseAppData()
    useListenToNotifications()
    useAppUrlListener()

    return (
        <div className="inner">
            {authUser && token && menuBarOn && <MenuBar />}
            <ErrorBoundary>
                <Main />
            </ErrorBoundary>
        </div>
    )
}

export default App
