import API from './API'

export default class FriendshipsAPI extends API {

    static async get(token) {

        const result = await fetch(`${this.API_URL}/friendships`, {
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async remove(uid, token) {

        const result = await fetch(`${this.API_URL}/friendships/${uid}`, {
            method: 'DELETE',
            headers: this.authHeader(token)
        })

        this.checkResultOK(result, null)

        return
    }

}