import React from "react"
import ReactDOM from "react-dom"
import App from "views/Main/App"
import * as serviceWorker from "serviceWorker"

import "bootstrap/dist/css/bootstrap.min.css"

// bootstrap functions into the window object
require("./bootstrap")

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
