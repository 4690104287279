import React from 'react'
import { Dropdown, Button } from 'react-bootstrap'
import { forwardRef } from 'react'
import { MdMoreVert } from 'react-icons/md'

const CustomMenu = forwardRef(({ onClick }, ref) => (
    <Button
        ref={ref}
        onClick={onClick}
    >
        <MdMoreVert />
    </Button>
))

export default function DotMenu({ id, item, actions, className }) {

    const menuList = actions.map(({ actionHandler, content, variant }, i) => (
        <Dropdown.Item
            as={Button}
            onClick={() => actionHandler(item)}
            key={`caseItem-${id}-${i}`}
        >
            <span className={`text-${variant}`}>{content}</span>
        </Dropdown.Item>
    ))

    return (
        <Dropdown className={className}>
            <Dropdown.Toggle as={CustomMenu} />
            <Dropdown.Menu>
                {menuList}
            </Dropdown.Menu>
        </Dropdown>
    )
}
