import React, { useRef, useEffect } from 'react'

import { chatsSelector } from 'slices/chats'
import { joinChannel, leaveChannel } from 'slices/socket'
import { socketSelector } from 'slices/socket'
import { useSelector, useDispatch } from 'react-redux'

export default function OuterMessageWrap({ children, id, handleMessageSent, handleMessageDeleted, handleLoadMore, handleLoadChat, WrapStyle }) {

    const dispatch = useDispatch()

    const {
        messages,
        currentChat,
        loadingMoreMessages,
        noMoreMessages,
    } = useSelector(chatsSelector)
    const { connected } = useSelector(socketSelector)

    const scrollRef = useRef()
    const lastScrollRef = useRef()
    const lastMessageIdRef = useRef()

    const scrollToBottom = (element) => {
        element.scrollTop = element.scrollHeight - element.clientHeight
        lastScrollRef.current = element.scrollHeight
    }

    const scrollMaintainPosition = (element) => {
        element.scrollTop = element.scrollHeight - lastScrollRef.current + element.scrollTop
        lastScrollRef.current = element.scrollHeight
    }

    /* On first page load, scroll to the bottom */
    useEffect(() => {
        scrollToBottom(scrollRef.current)
    }, [])

    /* When the message list gets longer, scroll to the bottom */
    useEffect(() => {
        if (messages[id] && messages[id].length > 0) {
            const lastMessage = messages[id][messages[id].length - 1]
            if (lastMessage.id !== lastMessageIdRef.current) {
                scrollToBottom(scrollRef.current)
                lastMessageIdRef.current = lastMessage.id
            } else {
                scrollMaintainPosition(scrollRef.current)
            }
        }
    }, [messages, id])

    /* On first page load, load chat for this user  */
    useEffect(() => {
        handleLoadChat()
    }, [handleLoadChat])

    /* Set up listeners for chat */
    useEffect(() => {
        const channelName = `chat.${currentChat.id}`
        const eventHandles = [
            {
                name: 'MessageSentEvent',
                handle: handleMessageSent,
            },
            {
                name: 'MessageDeletedEvent',
                handle: handleMessageDeleted,
            }
        ]
        if (connected && currentChat.id) {
            dispatch(joinChannel(channelName, eventHandles))
        }

        return () => {
            if (connected && currentChat.id) {
                dispatch(leaveChannel(channelName, eventHandles))
            }
        }
    }, [dispatch, connected, currentChat, handleMessageDeleted, handleMessageSent])

    /* load more messages when we hit the top of the screen */
    const handleScroll = (e) => {
        const { scrollTop } = e.target
        if (scrollTop < 50 && !loadingMoreMessages && !noMoreMessages) {
            handleLoadMore()
        }
    }

    return (
        <WrapStyle
            ref={scrollRef}
            onScroll={handleScroll}
        >
            {children}
        </WrapStyle>
    )
}
