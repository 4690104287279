import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { friendshipsSelector } from 'slices/friendships'
import { Avatar } from 'components/Elements/Avatar'
import { MdArrowBack, MdHome } from 'react-icons/md'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'

import * as ROUTES from 'constants/routes'
import IconButton from 'components/Elements/IconButton'

const StyledChatHeader = styled.div`
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    position: relative;
    z-index: 1000;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between
`

export default function ChatHeader({ friend_id }) {

    const { friends } = useSelector(friendshipsSelector)
    const history = useHistory()

    const friend = friends.find((friend) => friend.id === Number(friend_id))

    const goToFriends = () => {
        history.push(ROUTES.FRIENDS)
    }

    const goHome = () => {
        history.push(ROUTES.HOME)
    }

    return (
        <StyledChatHeader>
            <div>
                <Button
                    onClick={() => goToFriends()}
                    className="mr-2 border-0"
                    variant="outline-light"
                >
                    <MdArrowBack />
                    <Avatar
                        avatar={friend ? friend.avatar : null}
                        size="v-small"
                    />
                </Button>
                <span className="ml-2">{friend ? friend.username : null}</span>
            </div>
            <IconButton
                onClick={goHome}
                className="mr-2 border-0"
                variant="outline-light"
            >
                <MdHome />
            </IconButton>
        </StyledChatHeader>
    )
}
