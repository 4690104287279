import React, { useState } from 'react'
import {
    Modal,
} from 'react-bootstrap'
import SearchBar from 'components/Elements/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { findUsers, usersSelector, resetUsers, getNextUsers } from 'slices/users'
import { useSnackbar } from 'notistack'
import { MdDone } from 'react-icons/md'
import UserList from './UserList'
import { makeFriendshipRequest } from 'slices/friendshipRequests'
import { getFriends, friendshipsSelector } from 'slices/friendships'

export default function AddFriendModal({ open, onClose }) {

    const [searchTerm, setSearchTerm] = useState('')
    const [activeUserId, setActiveUserId] = useState(-1)

    const dispatch = useDispatch()
    const snacks = useSnackbar()
    const { users, loading, lastPage } = useSelector(usersSelector)
    const { pendingIds, friendRequestIds, friendIds } = useSelector(friendshipsSelector)

    const handleClose = () => {
        onClose()
    }

    const onChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(resetUsers())
        dispatch(findUsers(searchTerm))
            .catch(() => {
                snacks.enqueueSnackbar('Error find users', { variant: 'error' })
            })
    }

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target

        const scrollBottom = (scrollHeight - clientHeight) - scrollTop

        if (scrollBottom < 50 && loading === false && !lastPage) {
            dispatch(getNextUsers(searchTerm))
        }
    }

    const primaryAction = (id) => {
        setActiveUserId(id)
    }

    const handleRequestFriend = (id, username) => {
        dispatch(makeFriendshipRequest(id))
            .then(() => {
                dispatch(getFriends())
                snacks.enqueueSnackbar(`${username} requested`, { variant: "success" })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: "error" })
            })
    }

    const secondaryActions = [
        { actionHandler: handleRequestFriend, content: (<MdDone />) }
    ]

    const filteredUsersList = users.filter((user) => !(pendingIds.includes(user.id) || friendRequestIds.includes(user.id) || friendIds.includes(user.id)))

    return (
        <Modal
            size="xl"
            aria-labelledby="add-friend-title"
            centered
            onHide={handleClose}
            show={open}
            scrollable
            onScroll={handleScroll}
        >
            <Modal.Header closeButton>
                <Modal.Title id="add-friend-title">
                    Add friends
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchBar
                    searchTerm={searchTerm}
                    onChange={onChange}
                    onSubmit={onSubmit}
                />
                <UserList
                    users={filteredUsersList}
                    loading={loading}
                    primaryAction={primaryAction}
                    activeUserId={activeUserId}
                    secondaryActions={secondaryActions}
                    activeList={true}
                />
            </Modal.Body>

        </Modal>
    )
}