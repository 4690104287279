import React from 'react'
import styled from 'styled-components'

const StyledIcon = styled.div`
    font-size: ${({ size }) => size ? `${size}rem` : 'inherit'};
    display: inline-block;
    margin-right: 0.5em;
`

function Icon({ children, size = null, ...props }) {

    return (
        <StyledIcon size={size} {...props}>
            {children}
        </StyledIcon>
    )
}

export default Icon