import API from './API'

export default class FriendRequestAPI extends API {

    static async request(authUserId, uid, token) {

        const result = await fetch(`${this.API_URL}/users/${authUserId}/friendRequests/${uid}`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async cancel(authUserId, uid, token) {

        const result = await fetch(`${this.API_URL}/users/${authUserId}/friendRequests/${uid}`, {
            method: 'DELETE',
            headers: this.authHeader(token)
        })

        this.checkResultOK(result, null)

        return
    }

    static async reject(authUserId, uid, token) {

        const result = await fetch(`${this.API_URL}/users/${uid}/friendRequests/${authUserId}`, {
            method: 'DELETE',
            headers: this.authHeader(token)
        })

        this.checkResultOK(result, null)

        return
    }

    static async accept(authUserId, uid, token) {

        const result = await fetch(`${this.API_URL}/users/${uid}/friendRequests/${authUserId}`, {
            method: 'PUT',
            headers: this.authHeader(token)
        })

        this.checkResultOK(result, null)

        return
    }
}