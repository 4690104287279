import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import withAuthentication from 'components/Session/withAuthentication'
import ChatHeader from './Parts/ChatHeader'
import DirectChatArea from 'views/Chat/Parts/DirectChatArea'
import { useDispatch } from 'react-redux'
import { turnMenuBarOn, turnMenuBarOff } from 'slices/ui'
import { compose } from 'recompose'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'


function Chat() {
    const { uid } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(turnMenuBarOff())
        return () => {
            dispatch(turnMenuBarOn())
        }
    }, [dispatch])

    return (
        <>
            <ChatHeader
                friend_id={uid}
            />
            <DirectChatArea
                uid={uid}
            />
        </>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(Chat)