export const HOME = "/"

export const REGISTER = "/register"

export const LOGIN = "/login"

export const USER_PROFILE = "/profile"

export const SETTINGS = "/settings"

export const ACCOUNT = "/settings/account"

export const CHANGE_PASSWORD = "/settings/change-password"

export const HELP = "/settings/help"

export const VERIFY_EMAIL = "/verify-email"

export const FRIENDS = "/friends"

export const CHAT_STUB = "/chat/"

export const CHAT = `${CHAT_STUB}:uid`

export const SEND_SOS = "/send-sos"

export const REQUESTS = "/requests"

export const CASE = `${REQUESTS}/:caseId`

export const RESPONSES = "/responses"

export const SOS_NOTIFICATIONS = "/sos-notifications"