import React from 'react'
import styled from 'styled-components'
import SosPanelBoard from './SosPanelBoard'
import SosPanel from './SosPanel'
import { urgencies } from 'constants/sos'

export default function ChooseUrgency({ handleClick }) {
    return (
        <SosPanelBoard>
            {urgencies.map(({ id, value, name, color }) => (
                <ColouredSosPanel onClick={() => handleClick(id)} key={id} color={color} id={name.replace(' ', '_').toLowerCase()}>
                    {name}
                </ColouredSosPanel>
            ))}
        </SosPanelBoard>
    )
}

const ColouredSosPanel = styled(SosPanel)`
background-color: ${({ theme, color }) => theme[color].veryLight};
color: ${({ theme, color }) => theme[color].main};
`