import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { MdSearch } from 'react-icons/md'

export default function SearchBar(props) {

    const { onSubmit, searchTerm, onChange } = props

    return (
        <div>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="search">
                    <Form.Label srOnly>
                        Search
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <MdSearch />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>
        </div>
    )
}
