import React, { useState } from 'react'
import {
    Card,
    ListGroup,
    Accordion,
    Button,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { cancelCase, caseSelector, completeCase } from 'slices/cases'
import { useHistory } from 'react-router'

import * as ROUTES from 'constants/routes'
import styled from 'styled-components'
import { MdArrowBack, MdKeyboardArrowDown } from 'react-icons/md'
import ButtonGroup from 'components/Elements/ButtonGroup'
import { authUserSelector } from 'slices/session'
import SosHeaderForRequester from 'views/SOS/Parts/SosHeaderForRequester'
import SosHeaderForResponders from 'views/SOS/Parts/SosHeaderForResponders'
import Pill from 'components/Elements/Pill'
import DotMenu from 'components/Elements/DotMenu'

const StyledAccordion = styled(Accordion)`
    position: relative;
    z-index: 10000;
`

const ToggleArrow = styled.div`
    text-align: center;
    margin-bottom: -10px;
    transition: transform 0.1s linear;
    transform: rotate(${({ rotated }) => rotated ? '180deg' : '0deg'})
`

const PositionedDotMenu = styled(DotMenu)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`

export default function SosDetails({ caseId, isRequester, caseFinished }) {

    const [headerOpen, setHeaderOpen] = useState(false)

    const authUser = useSelector(authUserSelector)
    const { responder, urgency, category } = useSelector(caseSelector(caseId))

    const dispatch = useDispatch()
    const history = useHistory()

    const isResponder = responder && responder.id === authUser.id

    const cancelSos = ({ id }) => {
        dispatch(cancelCase(id))
            .then(() => {
                history.push(ROUTES.HOME)
            })
    }

    const completeSos = () => {
        dispatch(completeCase(caseId))
    }

    const cancelAction = {
        actionHandler: cancelSos,
        content: 'cancel',
        variant: 'danger',
    }

    const secondaryActions = [
        cancelAction
    ]

    const toggleHeader = () => {
        setHeaderOpen(o => !o)
    }

    const goBack = () => {
        history.goBack()
    }

    return (
        <StyledAccordion>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={toggleHeader}
                >
                    <div className="d-flex">
                        <Button
                            onClick={goBack}
                            className="border-0 mr-2"
                            variant="outline-dark"
                        >
                            <MdArrowBack />
                        </Button>
                        {isRequester
                            ? (
                                <SosHeaderForRequester
                                    caseId={caseId}
                                />
                            ) : (
                                <SosHeaderForResponders
                                    caseId={caseId}
                                />
                            )}
                    </div>
                    <ToggleArrow rotated={headerOpen}>
                        <MdKeyboardArrowDown />
                    </ToggleArrow>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                    <>
                        <ListGroup>
                            <ListGroup.Item>
                                Category: <category.Icon /> {category.name}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Pill color={urgency.color}>
                                    Help needed {urgency.name}
                                </Pill>
                            </ListGroup.Item>
                        </ListGroup>
                        {isResponder && !caseFinished && (
                            <ButtonGroup>
                                <Button
                                    variant="success"
                                    onClick={() => completeSos()}
                                >
                                    Complete
                                </Button>
                            </ButtonGroup>
                        )}
                    </>
                </Accordion.Collapse>
            </Card>
            {!caseFinished && isRequester && (
                <PositionedDotMenu
                    id={caseId}
                    item={{ id: caseId }}
                    actions={secondaryActions}
                />
            )}
        </StyledAccordion>
    )
}
