import { useEffect } from 'react'
import { Geolocation } from 'lib/Geolocation'
import { locationUpdateInterval } from 'constants/app'
import { useDispatch, useSelector } from 'react-redux'
import { sessionSelector, updateAccount, isEmailVerifiedSelector } from 'slices/session'

export default () => {

    const dispatch = useDispatch()
    const { token } = useSelector(sessionSelector)
    const isEmailVerified = useSelector(isEmailVerifiedSelector)


    useEffect(() => {
        const updateLocation = () => Geolocation.getCurrentPosition()
            .then(({ coords }) => {
                console.log(`${coords.latitude}, ${coords.longitude}`);
                dispatch(updateAccount({
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                }))
            })
            .catch((error) => {
                console.error(error)
            })

        const shouldRun = !!token && isEmailVerified
        shouldRun && updateLocation()
        const timer = shouldRun && setInterval(() => {
            updateLocation()
        }, locationUpdateInterval)

        return () => {
            shouldRun && clearInterval(timer)
        }
    }, [dispatch, token, isEmailVerified])
}