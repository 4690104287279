import React from 'react'
import SosPanelBoard from 'views/SOS/Parts/SosPanelBoard'
import SosPanel from 'views/SOS/Parts/SosPanel'
import { recipients } from 'constants/sos'

export default function ChooseRecipient({ handleClick }) {

    return (
        <SosPanelBoard>
            {
                recipients.map(({ id, name, Icon }) => (
                    <SosPanel onClick={() => handleClick(id)} key={id} id={name.replace(' ', '_').toLowerCase()}>
                        <Icon /><span style={{ marginLeft: '1rem' }}>{name}</span>
                    </SosPanel>
                ))
            }
        </SosPanelBoard>
    )

}