import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { verifyEmail, sessionSelector } from 'slices/session'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import * as ROUTES from 'constants/routes'
import { useSnackbar } from 'notistack'
import Spinner from 'components/Loading/Spinner'
import Page from 'components/Elements/Page'

/**
 * Gets the queryURL query param
 * 
 * POSTs to queryURL to verify email
 * 
 * Displays error/success message
 * 
 * Redirects to Home/Login depending if logged in or not
 */
function VerifyEmail() {

    const history = useHistory()
    const searchParams = history.location.search.substring(1)
    const urlParams = new URLSearchParams(searchParams)
    const queryURL = urlParams.get('queryURL')

    const dispatch = useDispatch()
    const { loading, hasErrors, error, token, emailVerified } = useSelector(sessionSelector)

    const snacks = useSnackbar()
    // when this page is hit, the dispatch event should only fire once
    // this should be based on a flag in the state
    // when hit once the flag is turned on
    // when the page is refreshed the flag is reset and turned on again

    const loggedIn = !!token

    const emailAlreadyVerified = (error) => error === 'Email already verified'

    useEffect(() => {
        dispatch(verifyEmail(queryURL))
            .then(() => {
                snacks.enqueueSnackbar("Email verified", { variant: "success" })
                if (loggedIn) history.push(ROUTES.HOME)
                else history.push(ROUTES.LOGIN)
            })
            .catch((error) => {
                if (emailAlreadyVerified(error.message)) {
                    snacks.enqueueSnackbar("Email already verified", { variant: "info" })
                    if (loggedIn) history.push(ROUTES.HOME)
                    else history.push(ROUTES.LOGIN)
                }
            })
    }, [queryURL, dispatch, history, loggedIn, snacks])

    const isEmailVerified = emailVerified || (hasErrors && emailAlreadyVerified(error))

    if ((!queryURL || hasErrors) && !isEmailVerified) {
        console.log(error)
        let errorMessage = ''
        if (error === "Too Many Requests") {
            errorMessage = (
                <p>
                    Too many attempts, try again later
                </p>
            )
        } else {
            errorMessage = (
                <>
                    <p>
                        Something was wrong with the link you clicked.
                    </p>
                    {/* <p>
                        Click this link to send another verification email (LINK TO BE MADE STILL)
                    </p> */}
                </>
            )
        }
        return (
            <Page>
                <Row className="justify-content-center">
                    <h1>___</h1>
                </Row>
                <Row className="justify-content-center">
                    <h2>Uh oh!</h2>
                </Row>
                {errorMessage}
            </Page>
        )
    }

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>___</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Verifying email</h2>
            </Row>
            {loading ?
                <Row className="justify-content-center">
                    <Spinner />
                </Row>
                :
                <>
                    <Row className="justify-content-center">
                        <p>Email Verified</p>
                    </Row>
                    <Row className="justify-content-center">
                        {loggedIn
                            ?
                            <Link to={ROUTES.HOME}>Go home</Link>
                            :
                            <Link to={ROUTES.LOGIN}>Login</Link>
                        }
                    </Row>
                </>
            }
        </Page>
    )
}

export default VerifyEmail