import React from 'react'
import { useSelector } from 'react-redux'
import { sessionSelector } from 'slices/session'
import { Redirect } from 'react-router'

import * as ROUTES from 'constants/routes'

export default WrappedComponent => props => {

    const { authUser, token } = useSelector(sessionSelector)

    if (!(authUser && token)) return (
        <Redirect to={ROUTES.LOGIN} />
    )

    if (authUser && token) return (
        <WrappedComponent {...props} />
    )
}
