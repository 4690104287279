import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    menuBarOn: true,
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        turnMenuBarOff: (state) => {
            state.menuBarOn = false
        },
        turnMenuBarOn: (state) => {
            state.menuBarOn = true
        }
    }
})

export const {
    turnMenuBarOff,
    turnMenuBarOn,
} = uiSlice.actions

export const uiSelector = state => state.ui

export default uiSlice.reducer