import {
    GiCrossedSwords,
    GiMedicalPackAlt,
    GiShoppingCart,
    GiSmallFire
} from 'react-icons/gi'
import { BsThreeDots } from 'react-icons/bs'
import { MdPeople, MdHome } from 'react-icons/md'

export const categories = [
    {
        id: 0,
        name: 'attack',
        Icon: GiCrossedSwords,
    },
    {
        id: 1,
        name: 'medical',
        Icon: GiMedicalPackAlt,
    },
    {
        id: 2,
        name: 'supplies',
        Icon: GiShoppingCart,
    },
    {
        id: 3,
        name: 'fire',
        Icon: GiSmallFire,
    },
    {
        id: 4,
        name: 'other',
        Icon: BsThreeDots,
    },
]

export const urgencies = [
    {
        id: 0,
        value: 100,
        name: 'right now',
        color: 'red'
    },
    {
        id: 1,
        value: 80,
        name: 'in the next hour',
        color: 'orange',
    },
    {
        id: 2,
        value: 60,
        name: 'in the next day',
        color: 'yellow',
    },
    {
        id: 3,
        value: 30,
        name: 'this week',
        color: 'midGreen',
    },
    {
        id: 4,
        value: 10,
        name: 'sometime soon',
        color: 'green',
    },
]

export const recipients = [
    {
        id: 0,
        name: 'Friends',
        Icon: MdPeople,
    },
    {
        id: 1,
        name: 'Responders',
        Icon: MdHome,
    },
]