import React from 'react'
import { Card, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { caseSelector } from 'slices/cases'

export default function SosHeaderForRequester({ caseId }) {

    const { recipients, status, responder } = useSelector(caseSelector(caseId))

    const isWaiting = status.short_name === 'waiting'
    const inProgress = status.short_name === 'in_progress'
    const isCancelled = status.short_name === 'cancelled'
    const isCompleted = status.short_name === 'complete'
    const isReferred = status.short_name === 'referred'

    let title = <span>SOS to {recipients.name}</span>
    if (inProgress) {
        title = <span>{responder.full_name} is on the case</span>
    } else if ((isCancelled || isCompleted) && responder) {
        title = <span>Helped by {responder.full_name}</span>
    }

    let badge = null
    if (isWaiting) {
        badge = <Badge pill variant="warning">{status.name}</Badge>
    } else if (inProgress) {
        badge = <Badge pill variant="primary">{status.name}</Badge>
    } else if (isCancelled) {
        badge = <Badge pill variant="secondary">{status.name}</Badge>
    } else if (isCompleted) {
        badge = <Badge pill variant="success">{status.name}</Badge>
    } else if (isReferred) {
        badge = <Badge pill variant="info">{status.name}</Badge>
    }

    return (
        <div>
            <Card.Title className="d-flex justify-content-between">
                <span>{title}</span>
            </Card.Title>
            <Card.Subtitle>{badge}</Card.Subtitle>
        </div>
    )
}
