import { createSlice } from "@reduxjs/toolkit"

import { AuthAPI } from "lib/API"
import echo from "utils/echo"

export const initialState = {
    authUser: {},
    token: null,
    loading: false,
    hasErrors: false,
    error: null,
    emailVerified: false,
    phoneVerified: false,
    phoneTokenSent: false,
}

const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        registerUser: (state) => {
            state.loading = true
        },
        registerUserSuccess: (state, { payload }) => {
            state.loading = false
            state.hasErrors = false
            state.error = null
            state.authUser = payload.data
            state.token = payload.token
        },
        registerUserFailure: (state, { payload }) => {
            state.loading = false
            state.hasErrors = true
            state.error = payload
        },
        loginUser: (state) => {
            state.loading = true
        },
        loginUserSuccess: (state, { payload }) => {
            state.loading = false
            state.authUser = payload.data
            state.phoneVerified = payload.data.phone_verified_at
            state.token = payload.token
            state.hasErrors = false
            state.error = null
        },
        loginUserFailure: (state, { payload }) => {
            state.loading = false
            state.hasErrors = true
            state.error = payload
        },
        logoutUser: (state) => {
            state.loading = true
        },
        logoutUserSuccess: (state) => {
            state.token = initialState.token
            state.authUser = initialState.authUser
            state.loading = false
        },
        logoutUserFailure: (state) => {
            state.hasErrors = true
        },
        verifyUserEmail: (state) => {
            state.loading = true
        },
        verifyUserEmailSuccess: (state, { payload }) => {
            state.authUser = payload.data
            state.emailVerified = true
            state.hasErrors = false
            state.error = null
            state.loading = false
        },
        verifyUserEmailFailure: (state, { payload }) => {
            state.hasErrors = true
            state.error = payload
            state.loading = false
        },
        sendPhoneVerificationToken: (state) => {
            state.loading = true
            state.phoneTokenSent = false
        },
        sendPhoneVerificationSuccess: (state) => {
            state.loading = false
            state.hasErrors = false
            state.error = null
            state.phoneTokenSent = true
        },
        sendPhoneVerificationFailure: (state, { payload }) => {
            state.loading = false
            state.hasErrors = true
            state.error = payload
            state.phoneTokenSent = false
        },
        verifyUserPhone: (state) => {
            state.loading = true
        },
        verifyUserPhoneSuccess: (state, { payload }) => {
            state.phoneVerified = true
            state.hasErrors = false
            state.error = null
            state.loading = false
        },
        verifyUserPhoneFailure: (state, { payload }) => {
            state.hasErrors = true
            state.error = payload
            state.loading = false
        },
        updateUserAccount: (state) => {
            state.loading = true
        },
        updateUserAccountSuccess: (state, { payload }) => {
            state.authUser = payload.data
            state.loading = false
        },
        updateUserAccountFailure: (state, { payload }) => {
            state.hasErrors = true
            state.error = payload
            state.loading = false
        },
        changeUserPassword: (state) => {
            state.loading = true
        },
        changeUserPasswordSuccess: (state, { payload }) => {
            state.token = payload.token
            state.loading = false
        },
        changeUserPasswordFailure: (state, { payload }) => {
            state.hasErrors = true
            state.error = payload
            state.loading = false
        },
        uploadUserAvatar: (state) => {
            state.loading = true
        },
        uploadUserAvatarSuccess: (state, { payload }) => {
            state.authUser = payload.data
            state.loading = false
        },
        uploadUserAvatarFailure: (state, { payload }) => {
            state.hasErrors = true
            state.error = payload
            state.loading = false
        },
        disableAccountStart: (state) => {
            state.loading = true
            state.error = null
        },
        disableAccountFailure: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        hydrationSuccess: (state) => {
            state.loading = false
            state.error = null
        },
    },
})

export const {
    registerUser,
    registerUserSuccess,
    registerUserFailure,
    loginUser,
    loginUserFailure,
    loginUserSuccess,
    logoutUser,
    logoutUserFailure,
    logoutUserSuccess,
    verifyUserEmail,
    verifyUserEmailFailure,
    verifyUserEmailSuccess,
    verifyUserPhone,
    verifyUserPhoneFailure,
    verifyUserPhoneSuccess,
    sendPhoneVerificationToken,
    sendPhoneVerificationFailure,
    sendPhoneVerificationSuccess,
    updateUserAccount,
    updateUserAccountSuccess,
    updateUserAccountFailure,
    changeUserPassword,
    changeUserPasswordFailure,
    changeUserPasswordSuccess,
    uploadUserAvatar,
    uploadUserAvatarFailure,
    uploadUserAvatarSuccess,
    disableAccountStart,
    disableAccountFailure,
    hydrationSuccess,
} = sessionSlice.actions

export const sessionSelector = (state) => state.session
export const authUserSelector = (state) => state.session.authUser
export const tokenSelector = (state) => state.session.token
export const isEmailVerifiedSelector = (state) => state.session.authUser.email_verified_at || state.session.emailVerified

export const register = (data) => {
    return async (dispatch) => {
        dispatch(registerUser())

        try {
            const body = await AuthAPI.register(data)

            dispatch(registerUserSuccess(body))

            return body
        } catch (error) {
            dispatch(registerUserFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        dispatch(
            updateAccount({
                one_signal_device_id: null,
                fcm_token: null,
            })
        )
        dispatch(logoutUser())

        const {
            session: { token },
        } = getState()

        try {
            let body = await AuthAPI.logout(token)
            dispatch(logoutUserSuccess(body))
            echo.stop()
            return true
        } catch (error) {
            dispatch(logoutUserFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const login = (data) => {
    return async (dispatch) => {
        dispatch(loginUser())

        try {
            let body = await AuthAPI.login(data)
            dispatch(loginUserSuccess(body))
            return true
        } catch (error) {
            console.log(error)
            dispatch(loginUserFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const verifyEmail = (url) => {
    return async (dispatch) => {
        dispatch(verifyUserEmail())

        try {
            let body = await AuthAPI.verifyEmail(url)
            dispatch(verifyUserEmailSuccess(body))
            return true
        } catch (error) {
            dispatch(verifyUserEmailFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const requestPhoneVerificationToken = () => {
    return async (dispatch, getState) => {
        dispatch(sendPhoneVerificationToken())

        const {
            session: { token },
        } = getState()

        try {
            await AuthAPI.requestPhoneToken(token)
            dispatch(sendPhoneVerificationSuccess())
            return true
        } catch (error) {
            dispatch(sendPhoneVerificationFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const verifyPhoneVerificationToken = (code) => {
    return async (dispatch, getState) => {
        dispatch(verifyUserPhone())

        const {
            session: { token },
        } = getState()

        try {
            await AuthAPI.verifyPhoneToken({ code }, token)
            dispatch(verifyUserPhoneSuccess())
            return true
        } catch (error) {
            dispatch(verifyUserPhoneFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const updateAccount = (data) => {
    return async (dispatch, getState) => {
        const {
            session: {
                token,
                authUser: { id },
            },
        } = getState()

        const isEmailVerified = isEmailVerifiedSelector(getState())
        if (!isEmailVerified) {
            return true
        }

        dispatch(updateUserAccount())

        try {
            let body = await AuthAPI.updateAccount(data, id, token)
            dispatch(updateUserAccountSuccess(body))
            return true
        } catch (error) {
            dispatch(updateUserAccountFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const changePassword = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeUserPassword())

        const {
            session: { token },
        } = getState()

        try {
            let body = await AuthAPI.changePassword(data, token)
            dispatch(changeUserPasswordSuccess(body))
            echo.stop()
            echo.start(body.token)
            return true
        } catch (error) {
            dispatch(changeUserPasswordFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const uploadAvatar = (data) => {
    return async (dispatch, getState) => {
        dispatch(uploadUserAvatar())

        const {
            session: { token },
        } = getState()

        try {
            let body = await AuthAPI.uploadAvatar(data, token)
            dispatch(uploadUserAvatarSuccess(body))
            return true
        } catch (error) {
            dispatch(uploadUserAvatarFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const disableAccount = () => {
    return async (dispatch, getState) => {
        dispatch(disableAccountStart())

        const {
            session: { token, authUser },
        } = getState()

        try {
            await AuthAPI.disableAccount(authUser.id, token)

            dispatch(logout())
        } catch (error) {
            dispatch(disableAccountFailure(error.message))
        }
    }
}

export default sessionSlice.reducer
