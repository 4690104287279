import API from './API'

export default class ChatAPI extends API {

    static async create(payload, token) {

        const result = await fetch(`${this.API_URL}/cases`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify(payload),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async get(caseId, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}`, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async getAll({ isOpen, responder }, token) {

        const url = new URL(`${this.API_URL}/cases`)
        url.searchParams.append('open', isOpen)
        responder && url.searchParams.append('responder', responder)

        const result = await fetch(url, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async update(caseId, data, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}`, {
            method: 'PATCH',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify(data),
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async updateLocation(caseId, data, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}/location`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify(data),
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async respond(caseId, token) {
        const result = await fetch(`${this.API_URL}/cases/${caseId}/respond`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async pass(caseId, token) {
        const result = await fetch(`${this.API_URL}/cases/${caseId}/pass`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async complete(caseId, token) {
        const result = await fetch(`${this.API_URL}/cases/${caseId}/complete`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
            ])
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async cancel(caseId, token) {

        const result = await fetch(`${this.API_URL}/cases/${caseId}`, {
            method: 'DELETE',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = result.json()

        this.checkResultOK(result, body)

        return body
    }
}