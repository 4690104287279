import React from 'react'
import { Card, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { caseSelector } from 'slices/cases'
import { authUserSelector } from 'slices/session'
import { Geolocation } from 'lib/Geolocation'

const kilometre = 1000

export default function SosHeaderForResponders({ caseId }) {

    const authUser = useSelector(authUserSelector)
    const { user, responder, status } = useSelector(caseSelector(caseId))

    const caseFinished = status && (status.short_name === 'complete' || status.short_name === 'cancelled')

    let title = ''
    if (!responder) {
        title = `${user.full_name} needs help`
    } else if (responder && !caseFinished) {
        title = `Helping ${user.full_name}`
    } else {
        title = `Helped ${user.full_name}`
    }

    let badge = null
    if (status.short_name === 'complete') {
        badge = <Badge pill variant="success">complete</Badge>
    } else if (status.short_name === 'cancelled') {
        badge = <Badge pill variant="secondary">cancelled</Badge>
    } else if (status.short_name === 'in_progress') {
        badge = <Badge pill variant="primary">in progress</Badge>
    } else if (status.short_name === 'referred') {
        badge = <Badge pill variant="info">Referred from Admin</Badge>
    }


    const distance = Geolocation.distance(authUser.latitude, authUser.longitude, user.latitude, user.longitude)

    return (
        <div>
            <Card.Title className="d-flex justify-content-between">
                <span>{title}</span>
            </Card.Title>
            <Card.Subtitle>
                Distance {distance < kilometre ? `${Math.round(distance / 1000 * 10) / 10}km` : `${distance}m`}
            </Card.Subtitle>
            {badge}
        </div>
    )
}
