import { createSlice } from '@reduxjs/toolkit'
import NotificationsAPI from 'lib/API/NotificationsAPI'

const initialState = {
    sosNotifications: [],
    loading: false,
    errors: null,
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        getNotificationsStart: (state) => {
            state.loading = true
        },
        getNotificationsSuccess: (state, { payload: { data } }) => {
            state.loading = false
            state.sosNotifications = data
        },
        getNotificationsFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        dismissNotificationStart: (state) => {
            state.loading = true
        },
        dismissNotificationSuccess: (state, { payload: notificationId }) => {
            state.loading = false
            state.sosNotifications = removeNotificationFromList(state.sosNotifications, notificationId)
        },
        dismissNotificationFailure: (state, { payload: { errorMessage, notificationId } }) => {
            state.loading = false
            state.errors = errorMessage
            state.sosNotifications = removeNotificationFromList(state.sosNotifications, notificationId)
        },
        dismissAllNotificationsStart: (state) => {
            state.loading = true;
        },
        dismissAllNotificationsSuccess: (state) => {
            state.loading = false;
            state.sosNotifications = [];
        },
        dismissAllNotificationsFailure: (state) => {
            state.loading = false;
        },
        notificationCreated: (state, { payload }) => {
            state.sosNotifications = [
                payload,
                ...state.sosNotifications,
            ]
        },
        caseNotificationsDeleted: (state, { payload: caseId }) => {
            state.sosNotifications = state.sosNotifications
                .filter(({ notification: { cases_id } }) =>
                    caseId !== cases_id)
        },
        notificationDeleted: (state, { payload: notificationId }) => {
            state.sosNotifications = state.sosNotifications
                .filter(({ notification: { id } }) =>
                    notificationId !== id)
        },
    }
})

function removeNotificationFromList(notificationList, notificationId) {
    return notificationList
        .filter(({ notification: { id } }) =>
            notificationId !== id)
}


export const {
    getNotificationsStart,
    getNotificationsSuccess,
    getNotificationsFailure,
    dismissNotificationStart,
    dismissNotificationSuccess,
    dismissNotificationFailure,
    dismissAllNotificationsFailure,
    dismissAllNotificationsStart,
    dismissAllNotificationsSuccess,
    notificationCreated,
    notificationDeleted,
    caseNotificationsDeleted,
} = notificationsSlice.actions

export const notificationsSelector = state => state.notifications

export const getNotifications = () => {
    return async (dispatch, getState) => {
        dispatch(getNotificationsStart())

        const { session: { token } } = getState()

        try {
            let body = await NotificationsAPI.getAll(token)
            dispatch(getNotificationsSuccess(body))
        } catch (error) {
            dispatch(getNotificationsFailure(error.message))
            console.error(error)
        }
    }
}

export const dismissNotification = (notificationId) => async (dispatch, getState) => {
    dispatch(dismissNotificationStart())

    const { session: { token } } = getState()

    try {
        await NotificationsAPI.dismiss(notificationId, token)
        dispatch(dismissNotificationSuccess(notificationId))
    } catch (error) {
        dispatch(dismissNotificationFailure({ errorMessage: error.message, notificationId }))
    }
}

export const dismissAllNotifications = (notificationIds) => async (dispatch, getState) => {
    dispatch(dismissAllNotificationsStart())

    const { session: { token } } = getState()

    try {
        await NotificationsAPI.dismissAll(notificationIds, token)
        dispatch(dismissAllNotificationsSuccess())
    } catch (error) {
        dispatch(dismissAllNotificationsFailure({ errorMessage: error.message }))
    }
}

export default notificationsSlice.reducer