import React, { useState } from "react"
import Page from "components/Elements/Page"
import { Row, Button, Form } from "react-bootstrap"

function SmsNotVerified({ requestToken, verifyToken }) {

    const [code, setCode] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        verifyToken(code)
    }
    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Phone not Verified</h1>
            </Row>
            <Row className="mb-2 justify-content-center">
                <Button
                    onClick={requestToken}
                    variant="outline-secondary"
                    size="lg"
                >
                    Get Verification Sms
                </Button>
            </Row>
            <Row className="justify-content-center">
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label srOnly>
                            Verification Code
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Verification code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        block
                        disabled={code === ''}
                    >
                        Verify Phone
                    </Button>
                </Form>
            </Row>
        </Page>
    )
}
export default SmsNotVerified
