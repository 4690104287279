import React from 'react'
import { useSelector } from 'react-redux'
import { sessionSelector } from 'slices/session'
import EmailNotVerified from 'views/Auth/EmailNotVerified'

export default WrappedComponent => props => {

    const { authUser, token } = useSelector(sessionSelector)

    const emailNotVerified = authUser.email_verified_at === null

    if (authUser && token && emailNotVerified) return (
        <EmailNotVerified />
    )

    if (authUser && token) return (
        <WrappedComponent {...props} />
    )
}
