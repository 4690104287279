import React from 'react'

import {
    Switch,
    Route
} from "react-router-dom"

import Login from 'views/Auth/Login';
import Register from 'views/Auth/Register';
import Broadcast from 'views/SOS/SOS';
import Settings from 'views/Settings/Settings';

import * as ROUTES from 'constants/routes'
import Account from 'views/Settings/Account';
import ChangePassword from 'views/Settings/ChangePassword';
import VerifyEmail from 'views/EmailAction/VerifyEmail';
import Friends from 'views/Friends/Friends';
import Chat from 'views/Chat/Chat';
import Case from 'views/SOS/Case';
import Requests from 'views/SOS/Requests';
import Responses from 'views/SOS/Responses';

function Main() {

    return (
        <Switch>
            <Route path={ROUTES.LOGIN}>
                <Login />
            </Route>
            <Route path={ROUTES.REGISTER}>
                <Register />
            </Route>
            <Route path={ROUTES.ACCOUNT}>
                <Account />
            </Route>
            <Route path={ROUTES.CHANGE_PASSWORD}>
                <ChangePassword />
            </Route>
            <Route path={ROUTES.SETTINGS}>
                <Settings />
            </Route>
            <Route path={ROUTES.VERIFY_EMAIL}>
                <VerifyEmail />
            </Route>
            <Route path={ROUTES.FRIENDS}>
                <Friends />
            </Route>
            <Route path={ROUTES.CHAT}>
                <Chat />
            </Route>
            <Route path={ROUTES.CASE}>
                <Case />
            </Route>
            <Route path={ROUTES.REQUESTS}>
                <Requests />
            </Route>
            <Route path={ROUTES.RESPONSES}>
                <Responses />
            </Route>
            <Route path={ROUTES.HOME}>
                <Broadcast />
            </Route>
        </Switch>
    )
}

export default Main