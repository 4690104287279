import React, { useState } from "react"
import { Row, Form, Button } from "react-bootstrap"
// eslint-disable-next-line
import { Link, useHistory, Redirect, Route } from "react-router-dom"
import * as ROUTES from "constants/routes"
import { useDispatch, useSelector } from "react-redux"
import { login, sessionSelector } from "slices/session"
import { useSnackbar } from "notistack"
import Page from "components/Elements/Page"

const INITIAL_FORM_DATA = {
    email: "",
    password: "",
}

function Login() {
    const [formDetails, setFormDetails] = useState(INITIAL_FORM_DATA)
    const dispatch = useDispatch()
    const snacks = useSnackbar()
    const history = useHistory()
    const { authUser, token, loading } = useSelector(sessionSelector)

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(
            login({
                ...formDetails,
                source: "app",
            })
        )
            .then(() => {
                history.push(ROUTES.HOME)
            })
            .catch((error) => {
                snacks.enqueueSnackbar(`Error: ${error.message}`, {
                    variant: "error",
                })
            })
    }

    const onChange = (name, value) => {
        setFormDetails({
            ...formDetails,
            [name]: value,
        })
    }

    const authenticated = authUser && token

    if (authenticated) return <Redirect to={ROUTES.HOME} />

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Login</h1>
            </Row>
            <Row className="justify-content-center">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label srOnly>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            autoComplete="email"
                            value={formDetails.email}
                            onChange={(e) => onChange("email", e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label srOnly>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            autoComplete="current-password"
                            value={formDetails.password}
                            onChange={(e) =>
                                onChange("password", e.target.value)
                            }
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        block
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </Form>
            </Row>
            <Row className="justify-content-center mt-3">
                <p>
                    Not Registered?{" "}
                    <Link to={ROUTES.REGISTER}>Create an account</Link>
                </p>
            </Row>
        </Page>
    )
}

export default Login
