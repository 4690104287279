import React from 'react'
import styled from 'styled-components';
import { STORAGE_URL } from 'constants/api'

const StyledAvatar = styled.img`
    border-radius: 50%;
    width: 100%;
    flex-shrink: 0;
    width: ${({dimension}) => dimension}px;
    height: ${({dimension}) => dimension}px;
    object-fit: cover;
`;

export function Avatar({ avatar, size }) {

    const avatarSrc = avatar ? `${STORAGE_URL}/avatars/${avatar}` : `${STORAGE_URL}/avatars/user.jpg`

    let dimension = '50'

    if (size === 'large') {
        dimension = '200'
    } else if (size === 'small') {
        dimension = '50'
    } else if (size === 'v-small') {
        dimension = '40'
    }

    return (
        <StyledAvatar
            src={avatarSrc}
            dimension={dimension}
        />
    )
}