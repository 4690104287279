import { createSlice } from '@reduxjs/toolkit'
import echo from 'utils/echo'


const initialState = {
    connected: false,
    channels: {},
    loading: false,
}

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        connectStart: (state) => {
            state.loading = true
        },
        connectSuccess: (state) => {
            state.loading = false
            state.connected = true
        },
        disconnectStart: (state) => {
            state.loading = true
        },
        disconnectSuccess: (state) => {
            state.connected = false
        },
        joinChannelSuccess: (state, { payload }) => {
            state.channels[payload] = true
        },
        leaveChannelSuccess: (state, { payload }) => {
            state.channels[payload] = undefined
        },
    }
})

export const {
    connectStart,
    connectSuccess,
    disconnectStart,
    disconnectSuccess,
    joinChannelSuccess,
    leaveChannelSuccess,
} = socketSlice.actions

export const socketSelector = state => state.socket

export const connectEcho = () => {
    return async (dispatch, getState) => {
        dispatch(connectStart())

        const { session: { token } } = getState()

        echo.start(token)

        dispatch(connectSuccess())
    }
}

export const disconnectEcho = () =>
    async (dispatch, getState) => {
        dispatch(disconnectStart)

        const { socket: { channels } } = getState()

        Object.entries(channels).forEach(([channelName, subscribed]) => {
            if (!subscribed) return
            dispatch(leaveChannel(channelName))
        })

        echo.stop()

        dispatch(disconnectSuccess())

    }

export const joinChannel = (channelName, eventHandlers = []) => (dispatch) => {

    if (!window.Echo) {
        console.log('Echo does not exist: not joining channel', channelName);
        return
    }
    const channel = window.Echo.private(channelName)

    eventHandlers.forEach(({ name, handle }) => {
        channel.listen(name, handle)
    })
    dispatch(joinChannelSuccess(channelName))
}

export const leaveChannel = (channelName, eventHandlers = []) => (dispatch) => {

    if (!window.Echo) {
        console.log('Echo does not exist: not leaving channel', channelName);
        return
    }
    const channel = window.Echo.private(channelName)

    eventHandlers.forEach(({ name }) => {
        channel.stopListening(name)
    })

    window.Echo.leave(channelName)
    dispatch(leaveChannelSuccess(channelName))
}

export default socketSlice.reducer