import React from 'react'

import SosPanelBoard from './SosPanelBoard'
import SosPanel from './SosPanel'

import { categories } from 'constants/sos'

export default function ChooseCategory({ handleClick }) {
    return (
        <SosPanelBoard>
            {categories.map(({ id, name, Icon }) => (
                <SosPanel key={id} id={name.replace(' ', '_').toLowerCase()} onClick={() => handleClick(id)} >
                    <Icon /><span style={{ marginLeft: '1rem' }}>{name}</span>
                </SosPanel>
            ))}
        </SosPanelBoard>
    )
}
