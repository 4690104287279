import React from 'react'
import BootSpinner from 'react-bootstrap/Spinner'

function Spinner() {
    return (
        <BootSpinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </BootSpinner>
    )
}

export default Spinner  