import React from 'react'
import { Row, Accordion, Card, Col } from 'react-bootstrap'
import CaseList from './Parts/CaseList'
import { useSelector, useDispatch } from 'react-redux'
import { cancelCase, mySOSesSelector } from 'slices/cases'
import Spinner from 'components/Loading/Spinner'
import withAuthentication from 'components/Session/withAuthentication'
import Page from 'components/Elements/Page'
import { compose } from 'recompose'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'

function Requests() {

    const dispatch = useDispatch()
    const { loading, cases } = useSelector(mySOSesSelector)

    const waitingCases = Object.entries(cases)
        .reverse()
        .map(([_id, caseItem]) => caseItem)
        .filter((caseItem) => caseItem.status.short_name === 'waiting' || caseItem.status.short_name === 'referred')
    const inProgressCases = Object.entries(cases)
        .reverse()
        .map(([_id, caseItem]) => caseItem)
        .filter((caseItem) => caseItem.status.short_name === 'in_progress')
    const finishedCases = Object.entries(cases)
        .reverse()
        .map(([_id, caseItem]) => caseItem)
        .filter((caseItem) => caseItem.status.short_name === 'complete' || caseItem.status.short_name === 'cancelled')

    const cancelSos = ({ id }) => {
        dispatch(cancelCase(id))
    }

    const cancelAction = {
        actionHandler: cancelSos,
        content: 'cancel',
        variant: 'danger',
    }

    const waitingActions = [
        cancelAction
    ]

    const inProgressActions = [
        cancelAction
    ]

    return (
        <Page>
            <Row className="justify-content-center">
                <Col>
                    <h1>Sos Requests</h1>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <Spinner />
                </Row>
            )}
            <Row>
                {waitingCases.length > 0 && (
                    <Card
                        className="flex-fill"
                    >
                        <Card.Header>
                            Waiting
                        </Card.Header>
                        <Card.Body className="p-0">
                            <CaseList
                                cases={waitingCases}
                                secondaryActions={waitingActions}
                            />
                        </Card.Body>
                    </Card>
                )}
            </Row>
            <Row>
                {inProgressCases.length > 0 && (
                    <Card
                        className="flex-fill"
                    >
                        <Card.Header>
                            In Progress
                        </Card.Header>
                        <Card.Body className="p-0">
                            <CaseList
                                cases={inProgressCases}
                                secondaryActions={inProgressActions}
                            />
                        </Card.Body>
                    </Card>
                )}
            </Row>
            <Row>
                <Accordion
                    className="flex-fill"
                    defaultActiveKey="friends"
                >
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="finished">
                            Finished
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="finished">
                            <Card.Body className="p-0">
                                {finishedCases.length > 0
                                    ? (
                                        <CaseList
                                            cases={finishedCases}
                                        />
                                    ) : (
                                        <div className="px-2 py-4 text-center">
                                            No SOS calls finished yet
                                        </div>
                                    )}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Row>
        </Page>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(Requests)