import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { Avatar } from 'components/Elements/Avatar'
import styled from 'styled-components'

const UserInfo = styled.span`
margin-left: 1rem;
overflow: hidden;
text-overflow: ellipsis;
max-width: 100px;
`

const ItemWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
.info {
    display: flex;
    align-items: center;
}
`

const IconButton = styled.div`
.iconBtn {
    background-color: ${({ variant }) => variant === 'secondary' ? 'red' : 'green'};
}

.iconBtn:hover {
    background-color: ${({ variant }) => variant === 'secondary' ? 'darkRed' : 'darkGreen'};
}
`

export default function UserItem({ id, username, avatar, activeList = false, active = false, secondaryActions = [], primaryAction }) {

    const actionList = secondaryActions.map(({ actionHandler, content, variant = "primary" }, i) => (
        <IconButton key={`userAction-${id}-${i}`} variant={variant}>
            <Button
                className="iconBtn"
                onClick={() => actionHandler(id, username)}
            >
                {content}
            </Button>
        </IconButton>
    ))

    const showsecondaryActions = (activeList && active) || !activeList

    return (
        <ListGroup.Item
            active={active}
            action
            as="div"
            onClick={() => primaryAction && primaryAction(id)}
            key={id}
        >
            <ItemWrapper>
                <div className="info">
                    <Avatar
                        avatar={avatar}
                        size="small"
                    />
                    <UserInfo className="username">
                        {username}
                    </UserInfo>
                </div>
                {showsecondaryActions && (actionList)}
            </ItemWrapper>
        </ListGroup.Item>
    )
}
