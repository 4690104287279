import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { useHistory } from 'react-router'
import { createCase, addRecipientsToNewCase, addUrgencyToNewCase, addCategoryToNewCase, addLocationToNewCase, clearNewCase } from 'slices/cases'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

import SosButton from './Parts/SosButton'
import ChooseRecipient from 'views/SOS/Parts/ChooseRecipient';
import withAuthentication from 'components/Session/withAuthentication'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'

import * as ROUTES from 'constants/routes'
import ChooseUrgency from './Parts/ChooseUrgency'
import ChooseCategory from './Parts/ChooseCategory'
import { Geolocation } from 'lib/Geolocation'
import { turnMenuBarOff, turnMenuBarOn } from 'slices/ui'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components'
import IconButton from 'components/Elements/IconButton'
import { Spinner } from 'react-bootstrap'

const INITIAL_STATE = 'initialStep'

function SOS() {

    const [sosWizardStep, setSosWizardStep] = useState(INITIAL_STATE)
    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()
    const snacks = useSnackbar()

    useEffect(() => {
        if (sosWizardStep === 'chooseRecipients') {
            Geolocation.getCurrentPosition()
                .then(({ coords }) => {
                    dispatch(addLocationToNewCase({
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                    }))
                })
                .catch((error) => console.error(error))

            dispatch(turnMenuBarOff())
        }
    }, [sosWizardStep, dispatch])

    useEffect(() => {
        return () => {
            dispatch(turnMenuBarOn())
        }
    }, [dispatch])

    const handleSos = () => {
        setSosWizardStep('chooseRecipients')
    }

    const handleRecipients = (recipients) => {
        setSosWizardStep('chooseUrgencyLevel')
        dispatch(addRecipientsToNewCase(recipients))
    }

    const handleUrgencyLevel = (urgency) => {
        setSosWizardStep('chooseCategory')
        dispatch(addUrgencyToNewCase(urgency))
    }

    const handleCategory = (category) => {
        dispatch(addCategoryToNewCase(category))
        sendSos()
    }

    const sendSos = () => {
        // create case and push user to case screen
        setLoading(true)
        dispatch(createCase())
            .then(({ data }) => {
                history.push(`${ROUTES.REQUESTS}/${data.caseItem.id}`)
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const cancelSos = () => {
        dispatch(clearNewCase())
        setSosWizardStep(INITIAL_STATE)
        dispatch(turnMenuBarOn())
    }

    const sosStages = {
        initialStep: (
            <SosButton
                id="sos_button"
                onClick={handleSos}
                size='200'
                borderSize='15'
            />
        ),
        chooseRecipients: (
            <ChooseRecipient handleClick={handleRecipients} />
        ),
        chooseUrgencyLevel: (
            <ChooseUrgency handleClick={handleUrgencyLevel} />
        ),
        chooseCategory: (
            <ChooseCategory handleClick={handleCategory} />
        )
    }

    return (
        <>
            { sosWizardStep !== 'initialStep' && (
                <CloseIcon
                    variant="none"
                    onClick={() => { cancelSos() }}
                >
                    <MdClose />
                </CloseIcon>
            )}
            {sosStages[sosWizardStep]}
            { loading === true && (
                <LoadingOverlay>
                    <Spinner
                        animation="border"
                        role="status"
                    >
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </LoadingOverlay>
            )}
        </>
    )
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`

const LoadingOverlay = styled.div`
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba( 64, 64, 64, 0.2);
`

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(SOS)