import React from 'react'
import styled from 'styled-components'

const SosButtonStyled = styled.button`
width: ${({ size }) => size}px;
height: ${({ size }) => size}px;

background-color: #cf251b;
background-clip: padding-box;

border: solid ${({ borderSize }) => borderSize}px transparent;
border-radius: 50%;

font-size: ${({ size }) => size / 4}px;
font-weight: 600;
color: white;

box-sizing: border-box;

&:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -${({ borderSize }) => borderSize}px;
    border-radius: inherit;
    background: linear-gradient(to right, #9b9f97, #20201f);
}
`

const CenterButton = styled(SosButtonStyled)`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
`

const OtherButton = styled(SosButtonStyled)`
position: relative;
`

export default function SosButton({ variant, size, borderSize, ...props }) {

    if (variant === 'button') {
        return <OtherButton
            {...props}
            size={size}
            borderSize={borderSize}>
            SOS
        </OtherButton>
    }

    return (
        <CenterButton
            {...props}
            size={size}
            borderSize={borderSize}
        >
            SOS
        </CenterButton>
    )
}
