import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "react-bootstrap"
import { useParams, useHistory } from "react-router"
import withAuthentication from "components/Session/withAuthentication"
import { useSelector, useDispatch } from "react-redux"
import {
    casesSelector,
    caseSelector,
    updateCaseLocation,
    locationUpdated,
    caseUpdated,
    caseCancelled,
    respondToCase,
    passCase,
    myCasesRespondedToSelector,
} from "slices/cases"

import * as ROUTES from "constants/routes"
import ErrorMessage from "components/Elements/ErrorMessage"
import Spinner from "components/Loading/Spinner"
import SosDetails from "./Parts/SosDetails"
import SosLocation from "./Parts/SosLocation"
import SosChatArea from "./Parts/SosChatArea"
import { Geolocation } from "lib/Geolocation"
import { authUserSelector } from "slices/session"
import { joinChannel, leaveChannel, socketSelector } from "slices/socket"
import Page from "components/Elements/Page"
import { MdChat, MdHome, MdPersonPinCircle } from "react-icons/md"
import { turnMenuBarOff, turnMenuBarOn } from "slices/ui"
import ButtonGroup from "components/Elements/ButtonGroup"
import { compose } from "recompose"
import withEmailVerification from "components/Session/withEmailVerification"
import IconButton from "components/Elements/IconButton"
import styled from "styled-components"
import withSmsVerification from "components/Session/withSmsVerification"

function Case() {
    const { caseId } = useParams()
    const authUser = useSelector(authUserSelector)
    const { chat_id, user_id, status } = useSelector(caseSelector(caseId))
    const { cases, loadingCase, respondingToCase } = useSelector(casesSelector)
    const { cases: myCases } = useSelector(myCasesRespondedToSelector)
    const { connected } = useSelector(socketSelector)
    const dispatch = useDispatch()
    const history = useHistory()

    const isRequester = authUser.id === user_id
    const responseNeeded =
        !isRequester &&
        status &&
        (status.short_name === "waiting" || status.short_name === "referred")
    const caseFinished =
        status &&
        (status.short_name === "complete" || status.short_name === "cancelled")
    const isCaseResponder =
        !respondingToCase && Object.keys(myCases).includes(caseId)

    const [locationView, setLocationView] = useState(responseNeeded)

    useEffect(() => {
        dispatch(turnMenuBarOff())
        return () => {
            dispatch(turnMenuBarOn())
        }
    }, [dispatch])

    useEffect(() => {
        const shouldRun = user_id && isRequester
        const updateLocation = () =>
            Geolocation.getCurrentPosition()
                .then(({ coords }) => {
                    console.log(`${coords.latitude}, ${coords.longitude}`)
                    dispatch(
                        updateCaseLocation(caseId, {
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                        })
                    )
                })
                .catch((error) => {
                    console.error(error)
                })
        const timer =
            shouldRun &&
            setInterval(() => {
                updateLocation()
            }, 20000)

        return () => {
            if (shouldRun) {
                console.log("stopping geolocation watcher")
                clearInterval(timer)
            }
        }
    }, [caseId, user_id, dispatch, isRequester])

    useEffect(() => {
        let channelName = `case.${caseId}`
        if (connected) {
            const eventHandles = [
                {
                    name: "CaseUpdatedEvent",
                    handle: ({ caseItem }) => {
                        dispatch(caseUpdated({ caseItem }))
                    },
                },
                {
                    name: "GeolocationUpdatedEvent",
                    handle: ({ location }) =>
                        dispatch(locationUpdated({ caseId, location })),
                },
                {
                    name: "CaseDeletedEvent",
                    handle: ({ caseItem }) => {
                        dispatch(caseCancelled({ caseItem }))
                    },
                },
            ]
            dispatch(joinChannel(channelName, eventHandles))
        }
        return () => {
            if (connected) {
                dispatch(leaveChannel(channelName))
            }
        }
    }, [caseId, connected, dispatch])

    const toggleLocationView = () => {
        setLocationView((v) => !v)
    }

    const handlePass = () => {
        dispatch(passCase(caseId)).then(() => {
            history.push(ROUTES.HOME)
        })
    }

    const handleRespond = () => {
        dispatch(respondToCase(caseId))
    }

    const goHome = () => {
        history.push(ROUTES.HOME)
    }

    if (!cases[caseId] && loadingCase)
        return (
            <Page>
                <Row className="justify-content-center">
                    <Spinner />
                </Row>
            </Page>
        )

    if (!cases[caseId])
        return (
            <Page>
                <Row className="justify-content-center">
                    <Col className="mt-5" xs={8}>
                        <ErrorMessage
                            title="Error"
                            message="This case doesn't exist"
                            action={{
                                handler: () => {
                                    history.push(ROUTES.HOME)
                                },
                                text: "Home",
                            }}
                        />
                    </Col>
                </Row>
            </Page>
        )

    return (
        <>
            <SosDetails
                caseId={caseId}
                isRequester={isRequester}
                caseFinished={caseFinished}
            />
            <FloatingButtons>
                <IconButton onClick={toggleLocationView}>
                    {locationView ? (
                        <MdChat />
                    ) : (
                        <MdPersonPinCircle style={{ fontSize: "2rem" }} />
                    )}
                </IconButton>
                <IconButton onClick={goHome}>
                    <MdHome />
                </IconButton>
            </FloatingButtons>
            {locationView && <SosLocation />}
            {((!isRequester && isCaseResponder) || isRequester) && (
                <SosChatArea
                    chatId={chat_id}
                    caseId={caseId}
                    withNotifications={locationView}
                    chatDisabled={responseNeeded || caseFinished}
                />
            )}
            {!isRequester && responseNeeded && (
                <ButtonGroup position="bottom">
                    <Button variant="secondary" onClick={handlePass}>
                        Pass
                    </Button>
                    <Button variant="success" onClick={handleRespond}>
                        Respond
                    </Button>
                </ButtonGroup>
            )}
        </>
    )
}

const FloatingButtons = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`

export default compose(withAuthentication, withEmailVerification, withSmsVerification)(Case)
