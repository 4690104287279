import { createSlice } from '@reduxjs/toolkit'
import { FriendshipsAPI } from 'lib/API'

const initialState = {
    pending: [],
    pendingIds: [],
    friends: [],
    friendIds: [],
    friendRequests: [],
    friendRequestIds: [],
    loading: false,
    errors: null,
}

const friendshipsSlice = createSlice({
    name: 'friendships',
    initialState,
    reducers: {
        getFriendshipsStart: (state) => {
            state.loading = true
        },
        getFriendshipsSuccess: (state, { payload }) => {
            state.loading = false
            state.pending = payload.pending
            state.pendingIds = payload.pending.map((user) => user.id)
            state.friends = payload.friends
            state.friendIds = payload.friends.map((user) => user.id)
            state.friendRequests = payload.friend_requests
            state.friendRequestIds = payload.friend_requests.map((user) => user.id)
        },
        getFriendshipsFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        removeFriendshipStart: (state) => {
            state.loading = true
        },
        removeFriendshipSuccess: (state) => {
            state.loading = false
        },
        removeFriendshipFailure: (state, payload) => {
            state.loading = false
            state.errors = payload
        },
    }
})

export const {
    getFriendshipsStart,
    getFriendshipsFailure,
    getFriendshipsSuccess,
    removeFriendshipStart,
    removeFriendshipSuccess,
    removeFriendshipFailure,
} = friendshipsSlice.actions

export const friendshipsSelector = state => state.friendships

export const getFriends = () => {
    return async (dispatch, getState) => {
        dispatch(getFriendshipsStart)

        const { session: { token } } = getState()

        try {
            const body = await FriendshipsAPI.get(token)
            dispatch(getFriendshipsSuccess(body))
        } catch (error) {
            dispatch(getFriendshipsFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const removeFriendship = (uid) => {
    return async (dispatch, getState) => {
        dispatch(removeFriendshipStart)

        const { session: { token } } = getState()

        try {
            await FriendshipsAPI.remove(uid, token)
            dispatch(removeFriendshipSuccess())
        } catch (error) {
            dispatch(removeFriendshipFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export default friendshipsSlice.reducer