import React from 'react'
import styled from 'styled-components'

const StyledFab = styled.button`
height: 3.5rem;
width: 3.5rem;
border-radius: 1000px;
background-color: ${({theme}) => theme.primaryDark};
color: white;
box-shadow: 
    0px 3px 5px -1px rgba(0,0,0,0.2),
    0px 6px 10px 0px rgba(0,0,0,0.14),
    0px 1px 18px 0px rgba(0,0,0,0.12);
position: fixed;
bottom: 1rem;
right: 1rem;
font-size: 2rem;
display: flex;
align-items: center;
justify-content: center;
`

export default function Fab({ children, onClick }) {
    return (
        <StyledFab onClick={onClick} >
            {children}
        </StyledFab>
    )
}
