import API from "./API"

export default class AuthAPI extends API {
    static async register(data) {
        const result = await fetch(`${this.API_URL}/register`, {
            method: "POST",
            headers: this.combineHeaders([this.sendJsonHeader()]),
            body: JSON.stringify(data),
        })
        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async login(data) {
        const result = await fetch(`${this.API_URL}/login`, {
            method: "POST",
            headers: this.combineHeaders([this.sendJsonHeader()]),
            body: JSON.stringify(data),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async logout(token) {
        const result = await fetch(`${this.API_URL}/logout`, {
            method: "POST",
            headers: this.combineHeaders([
                this.sendJsonHeader(),
                this.authHeader(token),
            ]),
        })
        const body = await result.json()

        if (!result.ok) {
            const errors = body.errors
            throw new Error(errors[0])
        }
        return body
    }

    static async verifyEmail(url) {
        const result = await fetch(url, {
            method: "POST",
            headers: this.combineHeaders([this.sendJsonHeader()]),
        })
        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async requestPhoneToken(token) {
        const result = await fetch(`${this.API_URL}/users/requestPhoneToken`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.receiveJsonHeader(),
            ])
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async verifyPhoneToken(data, token) {
        const result = await fetch(`${this.API_URL}/users/verifyPhoneToken`, {
            method: 'POST',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.receiveJsonHeader(),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify(data),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async updateAccount(data, uid, token) {
        const result = await fetch(`${this.API_URL}/users/${uid}`, {
            method: "PATCH",
            headers: this.combineHeaders([
                this.sendJsonHeader(),
                this.authHeader(token),
            ]),
            body: JSON.stringify(data),
        })
        const body = await result.json()

        if (!result.ok) {
            const errors = body.errors
            throw new Error(errors[0])
        }

        return body
    }

    static async changePassword(data, token) {
        const result = await fetch(`${this.API_URL}/changePassword`, {
            method: "POST",
            headers: this.combineHeaders([
                this.sendJsonHeader(),
                this.authHeader(token),
            ]),
            body: JSON.stringify(data),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async uploadAvatar(data, token) {
        const formData = new FormData()
        formData.append("avatar", data.avatar)

        const result = await fetch(`${this.API_URL}/profile`, {
            method: "POST",
            headers: this.combineHeaders([this.authHeader(token)]),
            body: formData,
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async disableAccount(id, token) {
        const result = await fetch(`${this.API_URL}/users/${id}`, {
            method: "DELETE",
            headers: this.combineHeaders([this.authHeader(token)]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }
}
