import React, { useState } from 'react'
import {
    Button,
    Row,
    Card,
    Col,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { authUserSelector } from 'slices/session'
import withAuthentication from 'components/Session/withAuthentication'
import ProfileForm from 'views/Settings/Parts/ProfileForm'
import ProfileDetails from './Parts/ProfileDetails'
import styled from 'styled-components'
import Icon from 'components/Elements/Icon'
import { MdEdit } from 'react-icons/md'

import EditAvatar from 'views/Settings/Parts/EditAvatar'
import { Avatar } from 'components/Elements/Avatar'
import Page from 'components/Elements/Page'
import { compose } from 'recompose'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'

const AvatarWrapper = styled.div`
    position: relative;
    margin: 1rem auto;
`

const EditAvatarButton = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    right: 0.5rem;
    bottom: 0.5rem;
`

function Account() {
    const {
        full_name,
        birth_date,
        gender,
        username,
        email,
        phone,
        location,
        bio,
        avatar,
    } = useSelector(authUserSelector)

    const [inEditMode, setInEditMode] = useState(false)
    const [openEditAvatar, setOpenEditAvatar] = useState(false)

    const handleOpenEditAvatar = () => {
        setOpenEditAvatar(true)
    }

    const handleCloseEditAvatar = () => {
        setOpenEditAvatar(false)
    }

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Account</h1>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} md={8} sm={10}>
                    <Card className="min-width-80">
                        <AvatarWrapper>
                            <Avatar
                                avatar={avatar}
                                size="large"
                            />
                            <EditAvatarButton
                                role="button"
                                onClick={() => { handleOpenEditAvatar() }}
                            >
                                <Icon size="1.2">
                                    <MdEdit />
                                </Icon>
                            Edit
                        </EditAvatarButton>
                            <EditAvatar
                                open={openEditAvatar}
                                handleClose={handleCloseEditAvatar}
                            />
                        </AvatarWrapper>
                        <Card.Body>
                            <Card.Title>{full_name}</Card.Title>
                            <Card.Subtitle>@{username}</Card.Subtitle>
                            <Card.Text>
                                {bio}
                            </Card.Text>
                        </Card.Body>
                        {inEditMode ?
                            <ProfileForm
                                full_name={full_name}
                                birth_date={birth_date}
                                gender={gender}
                                location={location}
                                bio={bio}
                                handleClose={() => setInEditMode(false)}
                            />
                            :
                            <ProfileDetails
                                email={email}
                                phone={phone}
                                birth_date={birth_date}
                                location={location}
                            />
                        }
                        {!inEditMode && <Button
                            variant="primary"
                            onClick={() => setInEditMode(true)}
                        >
                            Edit Profile
                    </Button>}
                    </Card>
                </Col>
            </Row>
        </Page>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(Account)