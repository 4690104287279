import { createSlice } from '@reduxjs/toolkit'

import { UsersAPI } from 'lib/API'

const initialState = {
    loading: false,
    users: [],
    total: null,
    next_page_url: null,
    errors: null,
    lastPage: false,
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersStart: (state) => {
            state.loading = true
        },
        getUsersSuccess: (state, { payload }) => {
            state.loading = false
            state.users = [ ...state.users, ...payload.data]
            state.total = payload.total
            state.next_page_url = payload.next_page_url
            state.lastPage = payload.to === payload.total
        },
        getUsersFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        resetUsers: (state) => {
            state.users = []
            state.total = null
            state.next_page_url = null
            state.errors = null
            state.lastPage = false
        }
    }
})

export const {
    getUsersStart,
    getUsersSuccess,
    getUsersFailure,
    resetUsers,
} = usersSlice.actions

export const usersSelector = state => state.users

export const findUsers = (searchTerm) => {
    return async (dispatch, getState) => {
        dispatch(getUsersStart())

        const { session: { token }} = getState()

        try {
            const body = await UsersAPI.findUsers(searchTerm, null, token)
            dispatch(getUsersSuccess(body))
            return true
        } catch (error) {
            dispatch(getUsersFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export const getNextUsers = (searchTerm) => {
    return async (dispatch, getState) => {
        dispatch(getUsersStart())

        const { session: { token }, users: { next_page_url }} = getState()

        try {
            const body = await UsersAPI.findUsers(searchTerm, next_page_url, token)
            dispatch(getUsersSuccess(body))
            return true
        } catch (error) {
            dispatch(getUsersFailure(error.message))
            throw new Error(error.message)
        }
    }
}

export default usersSlice.reducer