import styled, { css } from 'styled-components'

const bottom = css`
position: absolute;
bottom: 0;
left: 0;
right: 0;
`

export default styled.div`
${({ position }) => {
        if (position === 'bottom') return bottom
    }}

display: flex;

button {
    width: 100%;
    margin: 0.1em;
    padding: 0.5em;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
}
`