import React from 'react'
import { ListGroup } from 'react-bootstrap'
import RequestItem from './RequestItem'

export default function RequestList({ users, loading, actions = [], ...props }) {
    return (
        <ListGroup {...props}>
            {
                users.map(({ username, avatar, id }) => (
                    <RequestItem
                        id={id}
                        username={username}
                        avatar={avatar}
                        key={`friend-requests-${id}`}
                        actions={actions}
                    />
                ))
            }
        </ListGroup>
    )
}
