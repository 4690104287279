import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sessionSelector, updateAccount } from 'slices/session'
import { useSnackbar } from 'notistack'
import {
    Form,
    Button,
} from 'react-bootstrap'
import moment from 'moment'

function ProfileForm(props) {

    const { full_name, birth_date, gender, location, bio, handleClose } = props

    const { loading } = useSelector(sessionSelector)

    const [formDetails, setFormDetails] = useState({
        full_name,
        birth_date: birth_date ? birth_date : "",
        gender: gender ? gender : "",
        location: location ? location : "",
        bio: bio ? bio : "",
    })

    const dispatch = useDispatch()
    const snacks = useSnackbar()

    const onChange = (name, value) => {
        setFormDetails({
            ...formDetails,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(updateAccount(formDetails))
            .then(() => {
                snacks.enqueueSnackbar("Update Account", { variant: "success" })
                handleClose()
            })
            .catch((error) => {
                snacks.enqueueSnackbar(error.message, { variant: "error" })
            })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="accountName">
                <Form.Label srOnly>
                    Name
            </Form.Label>
                <Form.Control
                    placeholder="name"
                    required
                    value={formDetails.full_name}
                    onChange={(e) => onChange('full_name', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="accountBirthDate">
                <Form.Label srOnly>
                    Birthday
            </Form.Label>
                <Form.Control
                    type="date"
                    placeholder="birthday"
                    value={moment(formDetails.birth_date).format('YYYY-MM-DD')}
                    onChange={(e) => onChange('birth_date', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="accountGender">
                <Form.Label srOnly>
                    Gender
            </Form.Label>
                <Form.Control
                    as="select"
                    placeholder="gender"
                    value={formDetails.gender}
                    onChange={(e) => onChange('gender', e.target.value)}
                >
                    <option value="" defaultValue>Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Form.Control>
            </Form.Group>
            {/* <Form.Group controlId="accountLocation">
                <Form.Label srOnly>
                    Location
            </Form.Label>
                <Form.Control
                    placeholder="location"
                    value={formDetails.location}
                    onChange={(e) => onChange('location', e.target.value)}
                >
                </Form.Control>
            </Form.Group> */}
            <Form.Group controlId="accountBio">
                <Form.Label srOnly>
                    Bio
            </Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="bio"
                    value={formDetails.bio}
                    onChange={(e) => onChange('bio', e.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <Button
                type="submit"
                disabled={loading}
            >
                Save changes
            </Button>
            <Button
                variant="secondary"
                onClick={handleClose}
            >
                Cancel
            </Button>
        </Form>
    )
}

export default ProfileForm