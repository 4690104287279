import React, { forwardRef } from 'react'
import styled from 'styled-components'
import ReactAudioPlayer from 'react-audio-player';
import { Dropdown } from 'react-bootstrap'
import { MdCheck, MdHighlightOff, MdTimer } from 'react-icons/md'
import * as MESSAGE_TYPE from 'constants/messages'

const MessageToggle = forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={onClick}
    >
        {children}
    </div>
))

const MessageStatus = styled.span`
    margin-left: 1em;
`

const MessageSender = styled.span`
    font-size: 0.8em;
    color: #444;
`

export default function Message({ message, authUser, actions, isDirect, isAdmin }) {
    const { user_id, content, id, deleted, sending, user } = message
    const name = user?.full_name

    const messageActions = actions.map(({ actionHandler, content, variant }, i) => (
        <Dropdown.Item onClick={() => actionHandler(id, user_id)} key={`messageAction-${id}-${i}`}>
            <span className={variant ? `text-${variant}` : ''}>
                {content}
            </span>
        </Dropdown.Item>
    ))

    const isSender = user_id === authUser.id
    const isDeleted = deleted === 1

    var messageText = content;
	if (isDeleted) {
		messageText = <><MdHighlightOff /> Message Deleted</>;
	}
	else if (message.type === MESSAGE_TYPE.AUDIO) {
		messageText = <ReactAudioPlayer src={content} controls />;
	}

    const messageContent = (
        <>
            { (!(isSender || isDirect)) && (<MessageSender>{name}</MessageSender>)}
            <div>
                <span>{messageText}</span>
                {isSender && (<MessageStatus>{sending ? <MdTimer /> : <MdCheck />}</MessageStatus>)}
            </div>
        </>
    )

    return (
        <MessageWrap
            position={isSender ? 'right' : 'left'}
        >
            <StyledMessage sender={isSender ? 'me' : 'other'}>

                {isSender
                    ? (
                        <SenderMessage
                            isDeleted={isDeleted}
                            messageContent={messageContent}
                            messageActions={messageActions}
                        />
                    ) : (
                        <span>{messageContent}</span>
                    )}
            </StyledMessage>
        </MessageWrap >
    )
}

const SenderMessage = ({ isDeleted, messageContent, messageActions }) => {
    if (isDeleted) {
        return (
            <span>{messageContent}</span>
        )
    }

    return (
        <Dropdown>
            <Dropdown.Toggle as={MessageToggle}>
                {messageContent}
            </Dropdown.Toggle>
            <Dropdown.Menu flip>
                {messageActions}
            </Dropdown.Menu>
        </Dropdown>
    )
}

const StyledMessage = styled.div`
    border: 1px solid ${({ theme, sender }) => sender === 'me' ? theme.primaryLight : theme.primaryDark};
    color: ${({ theme, sender }) => sender === 'me' ? theme.primaryLight : theme.primaryDark};
    background-color: ${({ theme, sender }) => sender === 'me' ? theme.primaryDark : theme.primaryLight};
    border-radius: 20px;
    padding: 0.5rem;
    margin-top: 1rem;
`

const MessageWrap = styled.div`
    display: flex;
    justify-content: ${({ position }) => position === 'right' ? 'flex-end' : 'flex-start'};;
    padding-left: ${({ position }) => position === 'right' ? '50px' : '0'};
    padding-right: ${({ position }) => position === 'left' ? '50px' : '0'};
`