import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addSentMessage, markMessageDeleted, loadChat, loadMoreMessages, deleteMessage, sendMessage, sendAudioMessage } from 'slices/chats'
import ChatArea from 'views/Chat/Parts/ChatArea'
import OuterMessageWrap from './OuterMessageWrap'
import { authUserSelector } from 'slices/session'

const WrapStyle = styled.div`
    overflow: auto;
    position: fixed;
    top: 4rem;
    width: 100%;
    left: 0;
    bottom: 4.5rem;
    padding-top: 2rem;
    z-index: 1;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
`

export default function DirectChatArea({ uid }) {

    const dispatch = useDispatch()
    const authUser = useSelector(authUserSelector)

    const handleLoadChat = useCallback(() => {
        dispatch(loadChat(uid))
            .catch((error) => {
                console.error(error)
            })
    }, [dispatch, uid])

    const handleSendMessage = (messageText) => dispatch(sendMessage(messageText))

    const handleMessageSent = useCallback(({ message }) => {
        dispatch(addSentMessage({
            id: uid,
            newMessage: message,
            authUserId: authUser.id,
        }))
    }, [dispatch, uid, authUser.id])

    const handleDeleteMessage = (messageId) => {
        dispatch(deleteMessage(messageId))
    }

    const handleMessageDeleted = useCallback(({ message }) => {
        dispatch(markMessageDeleted({
            id: uid,
            deletedMessage: message,
        }))
    }, [dispatch, uid])

    const handleLoadMore = () => {
        dispatch(loadMoreMessages(uid))
    }

	const handleVoiceMessage = (b64) => {
		dispatch(sendAudioMessage(b64));
	}

    return (
        <OuterMessageWrap
            handleLoadMore={handleLoadMore}
            handleMessageSent={handleMessageSent}
            handleMessageDeleted={handleMessageDeleted}
            handleLoadChat={handleLoadChat}
            id={uid}
            WrapStyle={WrapStyle}
        >
            <ChatArea
                id={uid}
                handleSendMessage={handleSendMessage}
                handleDeleteMessage={handleDeleteMessage}
				handleVoiceMessage={handleVoiceMessage}
                isDirect={true}
            />
        </OuterMessageWrap>
    )
}
