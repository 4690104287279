import { API_URL } from 'constants/api'

export default class API {

    static API_URL = API_URL

    static sendJsonHeader = () => ({
        'Content-Type': 'application/json',
    })

    static receiveJsonHeader = () => ({
        'Accept': 'application/json',
    })

    static authHeader = (token) => ({
        'Authorization': 'Bearer ' + token,
    })

    static credentialsHeader = () => ({
        'Credentials': 'include',
    })

    static combineHeaders = (headers) => {
        const combinedHeaders = headers.reduce((combined, header) => ({
            ...combined,
            ...header,
        }))
        return ({
            ...combinedHeaders,
            ...this.credentialsHeader(),
            ...this.receiveJsonHeader()
        })
    }

    static checkResultOK = (result, body = {}) => {
        if (!result.ok) {
            const errors = body.hasOwnProperty('errors') ? body.errors : null
            let error = ""
            if (typeof errors === "string") {
                error = errors
            } else if (Array.isArray(errors)) {
                error = errors[0]
            } else {
                error = result.statusText
            }
            throw new Error(error)
        }
    }

}