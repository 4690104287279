import Echo from "laravel-echo"

const server = process.env.REACT_APP_LOCAL_SERVER
const port = process.env.REACT_APP_WEBSOCKETS_PORT || 6001
const host = process.env.REACT_APP_WEBSOCKETS_HOST || window.location.hostname
const key = process.env.REACT_APP_PUSHER_KEY
const cluster = process.env.REACT_APP_PUSHER_CLUSTER || null

function start(token) {

    let echoConfig = {
        broadcaster: 'pusher',
        key: key,
        cluster: cluster,
        namespace: 'App.API.Events',
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        },
        authEndpoint: `${server}/api/broadcasting/auth`,
    }

    if (process.env.NODE_ENV === 'development') {
        echoConfig['wsHost'] = host
        echoConfig['wsPort'] = port
        echoConfig['forceTLS'] = false
        echoConfig['disableStats'] = true
    }

    window.Pusher = require('pusher-js')
    //    window.Pusher.logToConsole = true

    window.Echo = new Echo(echoConfig)
}

function stop() {
    if (window.Echo) {
        window.Echo.connector.disconnect()
        window.Pusher = undefined;
        window.Echo = undefined;
    }
}

export default {
    start,
    stop,
}