import { createSlice } from '@reduxjs/toolkit'

import ChatAPI from 'lib/API/ChatAPI'
import * as CHAT from 'constants/chat'

const initialState = {
    loading: false,
	loadingState: false,
    loadingMoreMessages: false,
    noMoreMessages: false,
    chats: {},
    messages: {},
    currentChat: {},
    errorLoadingChat: false,
    errors: null,
}

const isDirect = (currentChat) => currentChat.type === CHAT.DIRECT

const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        start: (state) => {
            state.loading = true
        },
        success: (state) => {
            state.loading = false
            state.errors = null
        },
        error: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        createChatStart: (state) => {
            state.loading = true
        },
        createChatSuccess: (state, { payload: { data } }) => {
            state.loading = false
            state.errorLoadingChat = false
            state.chats = { ...state.chats, [data.recipient.id]: data }
            state.currentChat = data
        },
        createChatFailure: (state, { payload }) => {
            state.loading = false
            state.errorLoadingChat = true
            state.errors = payload
        },
        sendMessageStart: (state) => {
            state.loading = true
			state.loadingState = false;
        },
		sendAudioPart: (state, { payload }) => {
			state.loading = true;
			state.loadingState = payload;
		},
        sendMessageSuccess: (state, { payload: { newMessage, id } }) => {
            state.loading = false
			state.loadingState = false;
            state.errors = null

            // add message straight away with sending: true
            const userMessages = [
                ...state.messages[id],
                {
                    ...newMessage,
                    sending: true,
                }
            ]
            state.messages[id] = userMessages
        },
        sendMessageFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        addSentMessage: (state, { payload }) => {
            const { id, newMessage, authUserId } = payload

            if (authUserId === newMessage.user_id) {
                const markMessageSent = (state, newMessage) => {
                    const sentMessage = state.messages[id].find((message) => message.id === newMessage.id)
                    const messageIndex = state.messages[id].indexOf(sentMessage)
                    state.messages[id][messageIndex].sending = false
                }
                markMessageSent(state, newMessage)
            } else {
                const userMessages = [
                    ...state.messages[id],
                    newMessage,
                ]
                state.messages[id] = userMessages
            }
        },
        deleteMessageStart: (state) => {
            state.loading = true
        },
        deleteMessageSuccess: (state) => {
            state.loading = false
            state.errors = null
        },
        deleteMessageFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        markMessageDeleted: (state, { payload }) => {
            const { id, deletedMessage } = payload

            // find deleted message in the state, and change flag to deleted
            const userMessages = state.messages[id]
            for (let index = 0; index < userMessages.length; index++) {
                const message = userMessages[index];
                if (message.id === deletedMessage.id) {
                    state.messages[id][index]['deleted'] = 1
                    break
                }
            }
        },
        getMessagesStart: (state) => {
            state.loading = true
        },
        getMessagesSuccess: (state, { payload: { data, id } }) => {
            state.messages = {
                ...state.messages,
                [id]: data.messages.reverse(),
            }
            state.loading = false
            state.errors = null
        },
        getMessagesFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        getNewMessagesStart: (state) => {
            state.loading = true
        },
        getNewMessagesSuccess: (state, { payload: { data, id } }) => {
            const { newMessages } = data
            const userMessages = [
                ...state.messages[id],
                ...newMessages.reverse(),
            ]
            state.messages = {
                ...state.messages,
                [id]: userMessages,
            }
            state.loading = false
            state.errors = null
        },
        getNewMessagesFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        loadChatStart: (state) => {
            state.loading = true
        },
        loadChatSuccess: (state, { payload }) => {
            state.loading = false
            state.errors = null
            state.errorLoadingChat = null
            state.currentChat = state.chats[payload]
        },
        loadChatFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
        loadMoreMessagesStart: (state) => {
            state.loadingMoreMessages = true
        },
        loadMoreMessagesSuccess: (state, { payload: { data, id } }) => {
            const { moreMessages } = data
            if (moreMessages.length > 0) {
                const userMessages = [
                    ...moreMessages.reverse(),
                    ...state.messages[id],
                ]
                state.messages = {
                    ...state.messages,
                    [id]: userMessages,
                }
            } else {
                state.noMoreMessages = true
            }
            state.loadingMoreMessages = false
            state.errors = null
        },
        loadMoreMessagesFailure: (state, { payload }) => {
            state.loadingMoreMessages = false
            state.errors = payload
        },
        loadCaseChatStart: (state) => {
            state.loading = true
        },
        loadCaseChatSuccess: (state, { payload }) => {
            state.currentChat = payload
            state.loading = false
        },
        loadCaseChatFailure: (state, { payload }) => {
            state.loading = false
            state.errors = payload
        },
    }
})

export const {
    start,
    success,
    error,
    createChatStart,
    createChatSuccess,
    createChatFailure,
    sendMessageStart,
	sendAudioPart,
    sendMessageSuccess,
    sendMessageFailure,
    deleteMessageStart,
    deleteMessageSuccess,
    deleteMessageFailure,
    getMessagesStart,
    getMessagesSuccess,
    getMessagesFailure,
    getNewMessagesStart,
    getNewMessagesSuccess,
    getNewMessagesFailure,
    loadChatStart,
    loadChatSuccess,
    loadChatFailure,
    loadCaseChatStart,
    loadCaseChatSuccess,
    loadCaseChatFailure,
    addSentMessage,
    markMessageDeleted,
    loadMoreMessagesStart,
    loadMoreMessagesSuccess,
    loadMoreMessagesFailure,
    loadCaseChatAction,
} = chatsSlice.actions

export const chatsSelector = state => state.chats

/**
 * Loads the chat with user into currentChat slot from chats array.
 * 
 * If the chat is not found in the store, then it creates a new one.
 */
export const loadChat = (userId) => async (dispatch, getState) => {
    dispatch(loadChatStart())

    const { chats: { chats, messages } } = getState()

    // if the chat doesn't exist create one
    const chat = chats[userId]
    if (!chat) {
        await dispatch(createChat(userId))
    }

    // if we don't have any messages with this user
    // get them
    try {
        if (!messages[userId]) {
            await dispatch(getMessages(userId))
        } else {
            await dispatch(getNewMessages(userId))
        }
        dispatch(loadChatSuccess(userId))
    } catch (error) {
        dispatch(loadChatFailure(error.message))
    }

}

export const loadCaseChat = (caseId) => async (dispatch, getState) => {
    dispatch(loadCaseChatStart())
    const { cases: { cases }, chats: { messages } } = getState()

    if (!cases[caseId]) {
        dispatch(loadCaseChatFailure(`case ${caseId} doesn't exist`))
        return
    }

    const chat = cases[caseId].chat
    dispatch(loadCaseChatSuccess(chat))

    if (!messages[chat.id]) {
        await dispatch(getMessages(caseId))
    } else {
        await dispatch(getNewMessages(caseId))
    }

}

export const createChat = (userId) => async (dispatch, getState) => {
    dispatch(createChatStart())

    const { session: { token }, chats } = getState()

    const chat = chats[userId]
    if (chat) {
        dispatch(createChatSuccess(chat))
        return
    }

    try {
        const body = await ChatAPI.create(userId, token)
        dispatch(createChatSuccess(body))
        return
    } catch (error) {
        dispatch(createChatFailure(error.message))
        throw new Error(error)
    }
}

export const sendMessage = (message, caseId = null) => async (dispatch, getState) => {

    dispatch(sendMessageStart())

    const { session: { token }, chats: { currentChat } } = getState()

    try {
        let body
        if (isDirect(currentChat)) {
            const userId = currentChat.recipient.id
            body = await ChatAPI.sendMessage(userId, message, token)
            dispatch(sendMessageSuccess({
                newMessage: body.data,
                id: userId,
            }))
        } else {
            body = await ChatAPI.sendCaseMessage(caseId, message, token)
            dispatch(sendMessageSuccess({
                newMessage: body.data,
                id: currentChat.id,
            }))
        }
        return
    } catch (error) {
        dispatch(sendMessageFailure(error.message))
        throw new Error(error)
    }
}

export const sendAudioMessage = (b64, caseId = null) => async (dispatch, getState) => {

    dispatch(sendMessageStart())

    const { session: { token }, chats: { currentChat } } = getState()

    try {
        let body
        if (isDirect(currentChat)) {
            const userId = currentChat.recipient.id
            body = await ChatAPI.sendAudioMessage(userId, b64, token, (process) => {
				dispatch(sendAudioPart(process))
			})
            dispatch(sendMessageSuccess({
				type: 'audio',
                newMessage: body.data,
                id: userId,
            }))
        } else {
            body = await ChatAPI.sendAudioCaseMessage(caseId, b64, token, (process) => {
				dispatch(sendAudioPart(process))
			})
            dispatch(sendMessageSuccess({
				type: 'audio',
                newMessage: body.data,
                id: currentChat.id,
            }))
        }
        return
    } catch (error) {
        dispatch(sendMessageFailure(error.message))
        throw new Error(error)
    }
}

export const deleteMessage = (messageId, caseId = null) => async (dispatch, getState) => {

    dispatch(deleteMessageStart())

    const { session: { token }, chats: { currentChat } } = getState()

    const id = (isDirect(currentChat)) ? currentChat.recipient.id : caseId
    try {
        const body = (isDirect(currentChat))
            ? await ChatAPI.deleteMessage(id, messageId, token)
            : ChatAPI.deleteCaseMessage(id, messageId, token)
        dispatch(deleteMessageSuccess(body))
        return
    } catch (error) {
        dispatch(deleteMessageFailure(error.message))
        throw new Error(error)
    }

}

export const getMessages = (id) => async (dispatch, getState) => {

    dispatch(getMessagesStart())

    const { session: { token }, chats: { currentChat } } = getState()

    try {
        if (isDirect(currentChat)) {
            const body = await ChatAPI.getMessages(id, token)
            dispatch(getMessagesSuccess({ ...body, id }))
        } else {
            const body = await ChatAPI.getCaseMessages(id, token)
            dispatch(getMessagesSuccess({ ...body, id: currentChat.id }))
        }
        return
    } catch (error) {
        dispatch(getMessagesFailure(error.message))
        console.log(error)
    }

}

export const getNewMessages = (id) => async (dispatch, getState) => {

    dispatch(getNewMessagesStart())

    const { session: { token }, chats: { messages, currentChat } } = getState()

    const chatMessages = (isDirect(currentChat)) ? messages[id] : messages[currentChat.id]
    const lastMessageDate = chatMessages[chatMessages.length - 1]?.created_at

    try {
        if (!lastMessageDate) {
            throw new Error('no messages yet')
        }

        if (isDirect(currentChat)) {
            const body = await ChatAPI.getNewMessages(id, lastMessageDate, token)
            dispatch(getNewMessagesSuccess({ ...body, id }))
        } else {
            const body = await ChatAPI.getNewCaseMessages(id, lastMessageDate, token)
            dispatch(getNewMessagesSuccess({ ...body, id: currentChat.id }))
        }
        return
    } catch (error) {
        dispatch(getNewMessagesFailure(error.message))
    }

}

export const loadMoreMessages = (id) => async (dispatch, getState) => {

    dispatch(loadMoreMessagesStart())

    const { session: { token }, chats: { messages, currentChat } } = getState()

    const chatMessages = (isDirect(currentChat)) ? messages[id] : messages[currentChat.id]
    const earliestMessageDate = chatMessages[0].created_at

    try {
        if (isDirect(currentChat)) {
            const body = await ChatAPI.getMoreMessages(id, earliestMessageDate, token)
            dispatch(loadMoreMessagesSuccess({ ...body, id }))
        } else {
            const body = await ChatAPI.getMoreCaseMessages(id, earliestMessageDate, token)
            dispatch(loadMoreMessagesSuccess({ ...body, id: currentChat.id }))
        }
        return
    } catch (error) {
        dispatch(loadMoreMessagesFailure(error.message))
        throw new Error(error)
    }
}

export default chatsSlice.reducer