import React from 'react'
import { useSelector } from 'react-redux'
import { chatsSelector } from 'slices/chats'
import styled from 'styled-components'

import { Form, Button } from 'react-bootstrap'
import { MdSend, MdMicNone } from 'react-icons/md'

const MessageInputStyled = styled.div`
    width: 80%;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    margin-bottom: 0.5em;
`

export default function MessageInput({
    sendChatMessage,
    messageText,
    onChange,
    errorLoadingChat,
    loading,
    startRecordingAudio,
    stopRecordingAudio
}) {
	
	const {
        loadingState
    } = useSelector(chatsSelector)

    return (
        <MessageInputStyled>
            <Form
                className="w-100"
                onSubmit={sendChatMessage}
            >
                <Form.Row className="flex-nowrap">
                    <Form.Control
                        className="mr-2"
                        placeholder="Chat message"
                        aria-label="chat message"
                        as="textarea"
                        size="sm"
                        rows="2"
                        value={messageText}
                        onChange={(e) => onChange(e.target.value)}
                    />
                    <Button type="submit" disabled={messageText === '' || errorLoadingChat || loading}>
                        <MdSend />
                    </Button>&nbsp;
                    <Button
                        className={loadingState !== false? `progress-` + loadingState.perc : ``}
                        type="button"
                        onMouseDown={startRecordingAudio}
                        onMouseUp={stopRecordingAudio}
                        onTouchStart={startRecordingAudio}
                        onTouchEnd={stopRecordingAudio}
                    >
                        <MdMicNone />
                    </Button>
                </Form.Row>
            </Form>
        </MessageInputStyled>
    )
}
