import { combineReducers } from "@reduxjs/toolkit"

import sessionReducer from 'slices/session'
import usersReducer from 'slices/users'
import friendshipRequestsReducer from 'slices/friendshipRequests'
import friendshipsReducer from 'slices/friendships'
import chatsReducer from 'slices/chats'
import socketReducer from 'slices/socket'
import casesReducer from 'slices/cases'
import uiReducer from 'slices/ui'
import notificationsReducer from 'slices/notifications'

const rootReducer = combineReducers({
    session: sessionReducer,
    users: usersReducer,
    friendshipRequests: friendshipRequestsReducer,
    friendships: friendshipsReducer,
    chats: chatsReducer,
    socket: socketReducer,
    cases: casesReducer,
    ui: uiReducer,
    notifications: notificationsReducer,
})

export default rootReducer