import React, { useState } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import withAuthentication from 'components/Session/withAuthentication'
import { useDispatch } from 'react-redux'
import { changePassword } from 'slices/session'
import { useSnackbar } from 'notistack'
import Page from 'components/Elements/Page'
import { compose } from 'recompose'
import withEmailVerification from 'components/Session/withEmailVerification'
import withSmsVerification from 'components/Session/withSmsVerification'

const INITIAL_STATE = {
    currentPassword: '',
    password: '',
    password_confirmation: '',
    username: '',
}

function ChangePassword() {

    const [formDetails, setFormDetails] = useState(INITIAL_STATE)

    const dispatch = useDispatch()
    const snacks = useSnackbar()

    const onChange = (name, value) => {
        setFormDetails({
            ...formDetails,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(changePassword(
            Object.fromEntries(Object.entries(formDetails).filter(([key]) => key !== 'username'))
        ))
            .then(() => {
                snacks.enqueueSnackbar('Changed password', { variant: "success" })
            })
            .catch((error) => {
                snacks.enqueueSnackbar(`Error: ${error.message}`, { variant: "error" })
            })
    }

    const passwordsMatch = formDetails.password !== '' &&
        formDetails.password === formDetails.password_confirmation

    return (
        <Page>
            <Row className="justify-content-center">
                <h1>Password</h1>
            </Row>
            <Row className="justify-content-center">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formUsername" className="sr-only">
                        <Form.Label srOnly>
                            Current Password
                        </Form.Label>
                        <Form.Control
                            required
                            autoComplete="username"
                            placeholder="username"
                            value={formDetails.username}
                            onChange={(e) => onChange('username', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCurrentPassword">
                        <Form.Label srOnly>
                            Current Password
                        </Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="current-password"
                            placeholder="Current password"
                            value={formDetails.currentPassword}
                            onChange={(e) => onChange('currentPassword', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label srOnly>
                            Current Password
                        </Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="password"
                            placeholder="New password"
                            value={formDetails.password}
                            onChange={(e) => onChange('password', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNewPasswordConfirmation">
                        <Form.Label srOnly>
                            Confirm Password
                        </Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="confirm-password"
                            placeholder="Confirm password"
                            value={formDetails.password_confirmation}
                            onChange={(e) => onChange('password_confirmation', e.target.value)}
                        />
                    </Form.Group>
                    <Button
                        type="submit"
                        disabled={!passwordsMatch}
                    >
                        Change Password
                    </Button>
                </Form>
            </Row>
        </Page>
    )
}

export default compose(
    withAuthentication,
    withSmsVerification,
    withEmailVerification,
)(ChangePassword)