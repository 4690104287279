import { useSnackbar } from "notistack"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { requestPhoneVerificationToken, sessionSelector, verifyPhoneVerificationToken } from "slices/session"
import SmsNotVerified from "views/Auth/SmsNotVerified"

export default (WrappedComponent) => (props) => {
    const { authUser, token, phoneVerified } = useSelector(sessionSelector)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const requestToken = () => {
        dispatch(requestPhoneVerificationToken())
        .then(() => {
            enqueueSnackbar('Phone verification SMS sent', { variant: 'success' })
        })
        .catch(({ message }) => {
            enqueueSnackbar(message, { variant: 'error' })
        })
    }

    const verifyToken = (code) => {
        dispatch(verifyPhoneVerificationToken(code))
        .then(() => {
            enqueueSnackbar('Phone verified', { variant: 'success' })
        })
        .catch(({ message }) => {
            enqueueSnackbar(message, { variant: 'error' })
        })
    }

    if (authUser && ( authUser.phone_verified_at === null && !phoneVerified ) ) {
        if (token)
            return (
                <SmsNotVerified requestToken={requestToken} verifyToken={verifyToken} />
            )
    }

    if (authUser && token) return <WrappedComponent {...props} />
}
