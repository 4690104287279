import API from './API'

export default class NotificationsAPI extends API {

    static async getAll(token) {

        const result = await fetch(`${this.API_URL}/notifications/sos`, {
            method: 'GET',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async dismiss(notificationId, token) {

        const result = await fetch(`${this.API_URL}/notifications/sos/${notificationId}`, {
            method: 'DELETE',
            headers: this.combineHeaders([
                this.authHeader(token),
            ]),
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }

    static async dismissAll(notificationIds, token) {

        const result = await fetch(`${this.API_URL}/notifications/sos`, {
            method: 'DELETE',
            headers: this.combineHeaders([
                this.authHeader(token),
                this.sendJsonHeader(),
            ]),
            body: JSON.stringify({
                notificationIds: notificationIds,
            })
        })

        const body = await result.json()

        this.checkResultOK(result, body)

        return body
    }
}