import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { socketSelector, joinChannel, leaveChannel } from 'slices/socket'
import { authUserSelector } from 'slices/session'
import { notificationCreated, caseNotificationsDeleted, notificationDeleted } from 'slices/notifications'

export default () => {

    const dispatch = useDispatch()
    const { connected } = useSelector(socketSelector)
    const authUser = useSelector(authUserSelector)

    useEffect(() => {
        const canJoinChannel = connected && authUser && authUser.id && authUser.email_verified_at !== null

        const channelName = `sos-notifications.${authUser.id}`

        const handleNotificationCreated = ({ notification }) => {
            dispatch(notificationCreated(notification))
        }

        const handleNotificationDeleted = ({ notificationId }) => {
            dispatch(notificationDeleted(notificationId))
        }

        const handleCaseNotificationsDeleted = ({ caseId }) => {
            dispatch(caseNotificationsDeleted(caseId))
        }

        const eventHandles = [
            {
                'name': 'SosNotificationCreatedEvent',
                'handle': handleNotificationCreated,
            },
            {
                'name': 'SosNotificationDeletedEvent',
                'handle': handleNotificationDeleted,
            },
            {
                'name': 'SosNotificationsDeletedEvent',
                'handle': handleCaseNotificationsDeleted,
            },
        ]

        if (canJoinChannel) {
            dispatch(joinChannel(channelName, eventHandles))
        }

        return () => {
            if (canJoinChannel) {
                dispatch(leaveChannel(channelName, eventHandles))
            }
        }
    }, [dispatch, connected, authUser, authUser.email_verified_at])
}