import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { Avatar } from 'components/Elements/Avatar'

export default function RequestItem({ id, username, avatar, actions }) {

    const secondaryActions = actions.map(({ actionHandler, content, variant = '' }, i) => (
        <Button
            onClick={() => { actionHandler(id, username) }}
            variant={variant === 'secondary' ? 'outline-secondary' : 'primary'}
            size={variant === 'secondary' ? 'sm' : ''}
            className='mr-2'
            key={`requestitem-${id}-${i}`}
        >
            {content}
        </Button>
    ))

    return (
        <ListGroup.Item>
            <div className="d-flex mb-2">
                <Avatar
                    avatar={avatar}
                    size="small"
                />
                <span className="ml-2">{username} wants to be friends</span>
            </div>
            {secondaryActions}
        </ListGroup.Item>
    )
}
