import React from 'react'
import { ListGroup } from 'react-bootstrap'
import Icon from 'components/Elements/Icon'
import {
    MdEmail,
    MdDateRange,
    MdPhone,
    MdLocationOn,
} from 'react-icons/md'
import moment from 'moment'

function ProfileDetails(props) {

    const { email, phone, birth_date, location } = props

    return (
        <ListGroup variant="flush">
            <ListGroup.Item>
                <Icon>
                    <MdEmail />
                </Icon>
                {email}
            </ListGroup.Item>
            <ListGroup.Item>
                <Icon>
                    <MdPhone />
                </Icon>
                {phone}
            </ListGroup.Item>
            {location && (<ListGroup.Item>
                <Icon>
                    <MdLocationOn />
                </Icon>
                {location}
            </ListGroup.Item>
            )}
            { birth_date && (<ListGroup.Item>
                <Icon>
                    <MdDateRange />
                </Icon>
                {moment(birth_date).format('Do MMM YYYY')}
            </ListGroup.Item>
            )}
        </ListGroup>
    )
}

export default ProfileDetails