import React from 'react'
import { compose, withProps } from 'recompose'
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps'

import * as MAPS from 'constants/maps'
import { useSelector } from 'react-redux'
import { caseSelector } from 'slices/cases'
import { useParams } from 'react-router'

const mapUrl = new URL("https://www.google.com/maps/api/js")
mapUrl.searchParams.append('key', MAPS.API_KEY)

function SosLocation() {

    const { caseId } = useParams()
    const { latitude, longitude } = useSelector(caseSelector(caseId))

    return (
        <div>
            <GoogleMap
                defaultZoom={17}
                center={{ lat: latitude, lng: longitude }}
            >
                <Marker position={{ lat: latitude, lng: longitude }} />
            </GoogleMap>
        </div>
    )
}

export default compose(
    withProps({
        googleMapURL: mapUrl.toString(),
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{
            position: 'absolute',
            top: 0,
            bottom: '70px',
            left: 0,
            right: 0,
            zIndex: 1,
        }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap,
)(SosLocation)